import navItems from "./navItems"
export default {
  slug: "caf-standard",
  url: "/caf-standard",
  title: "CAF Standard",
  content: {
    section1: {
      h1: {
        head: "“Quality Tested” to the ",
        tail: "Clean Air Filter Standard.",
      },
      p1:
        "You place your trust in our products to protect you from harm. That is a responsibility we do not take lightly. We honor your trust through our commitment to going beyond what is expected and a relentless devotion to doing more than required. Because what’s acceptable to most is simply not good enough for us. We test ourselves and our products to a higher standard and refuse to settle for anything less than exceptional.",
      p2:
        "Every one of our products is built right, every time. And through a constant cycle of rigorous, methodical, and painstaking testing and evaluation, we reinforce our dedication to quality because that’s what keeps our customers safe, healthy, and satisfied year after year.",
      p3:
        "CAF is in a unique position to be able to completely characterize environmental enclosures with accepted particulate and vapor test methodologies. In addition, CAF utilizes a patented Cab Integrity Test. Specific Cab Protection Levels are a balance between “cab integrity” and “filter efficiency.”",
      p4: `Operator Protection Level Parameters and Field Testing Criteria used by CAF:`,
      ul: {
        li1: `Operator Protection Level testing is based on a total cab efficiency at 0.3µm`,
        li2: `Providing the operator with a minimum of 17.66 CFM (30 m3/h) of clean air at all`,
        li3: `Reducing leaks in the negative air plenum of the HVAC`,
      },
      p5: `Cab pressurization is the most misunderstood characteristic of Protection Level. Cab pressure, whether high or low, is NOT an indication of safe operator protection levels.`,
      p6: `Once a cab is field-tested for protection levels with Optical Particle Counters or CO2, the Differential Pressure Gage (DPG) can be used as an indication of changing cabin airflow. A calibrated, the DPG will give an indication of filter loading, cab seal degradation, electrical changes, improper filter installation, etc., while indicating minimum flow rates.`,
      a1: `Contact CAF`,
      a2: `Recommended Flow Rate`,
      a3: `Particulate System Filter Test Procedure`,
      a4: `Vapor Test Comparisons `,
    },
    middleSection: {
      article1: {
        h2: navItems[1].children[0].label,
        p:
          "Clean Air Filter’s dedicated air quality lab is where each product undergoes rigorous testing to ensure that customers receive high-quality air filters and air filtration protection systems.",
      },
      article2: {
        h2: navItems[1].children[1].label,
        p:
          "Equipped with an exclusive testing methodology that goes beyond established standards, Clean Air Filter creates superior product quality.",
      },
      // article3: {
      //   h2: navItems[1].children[2].label,
      // },
      article4: {
        h2: "DDPA Sebastian testimonial",
        p: "",
      },
    },
  },
}
