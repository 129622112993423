export default {
  content: `<section>
        <h2>System Operation</h2>

        <p>Airflow through the CAF FPS 55 Series system, with a CAF48R or CAF47R filter, is as follows:</p>
        <p>1. Contaminated air is drawn into the system through the pre-cleaner. The circular motion of the pre-cleaner separates out larger particulates, dramatically increasing filter life.</p>
        <p>2. Air passes through the three-stage primary filter.</p>
        <ul style="list-style: none;">
            <li>
                <p><span class="stage-bullet">Stage 1</span> is a standard <em>PAPER</em> media designed for the heavy dust environment of agricultural, industrial, mining, and off-road conditions. Also, the paper is superior in absorbing liquid aerosol droplets that are present in the air.</p>
            </li>
            <li>
                <p><span class="stage-bullet">Stage 2</span> is respirator-grade <em>ACTIVATED CARBON</em>. The porous nature of this media effectively adsorbs organic vapors, such as diesel and chemical fumes. CAF’s customized, refined activated carbon works on surface area, and our patented design maximizes this principle.</p>
            </li>
            <li>
                <p><span class="stage-bullet">Stage 3</span> is a sub-micron <em>FINAL FILTER</em>  that provides protection of up to 99.998 @ 0.3µm while allowing maximum airflow.</p>
            </li>
        </ul>
        <p>3. Air exiting the FPS unit creates a positive flow of clean air inside a properly sealed cab. This positive flow pushes clean air out of the leaks in the micro-environment side of the system, rather than allowing contaminated air to enter. A differential pressure gauge is available to measure the difference in pressure between the inside and outside of the cab, monitoring flow rates and filter life.</p>
    </section>`,
  files: ["CAF Recommended Flow Rate"],
}
