import React from "react";
import phoneStyle from './phoneButton.module.scss';
import { ReactComponent as PhoneIcon } from './icons/phone-alt-solid.svg';

export default function PhoneButton(props) {
    return <div className={phoneStyle.mobile}
        style={props.style}
    >
        {<a className={phoneStyle.icon} href={"tel:712.748.3642"}><PhoneIcon></PhoneIcon></a>}
    </div>;
}