import systemOperation from "./system-operation"
import pressurization from "./pressurization"
export default {
  slug: "fps55",
  url: "/products/filtration-protection-systems/fps55",
  title: "FPS 55",
  content: {
    section1: {
      h1: {
        head: "Compact",
        tail: "FPS 55 Series.",
      },
      p1:
        "This compact unit is easy to install and requires minimal space.  Every FPS 55 Series is factory vapor tested for integrity at .00038 micron, allowing CAF’s patented test procedure for validation. This same procedure, along with particulate testing, can be used to completely characterize environmental enclosure in the field.  CAF has proven technology with over 30 years of experience.",
    },
    section2: {
      h2: "FPS 55.1",
      ul: {
        li0:
          "<p>Smallest quantifying system available in the market, 10.5” x 10” x 18”</p>",
        li1: "<p>Heavy duty powder coated metal construction</p>",
        li2: "<p>115 volt AC</p>",
        li3: "<p>50 CFM</p>",
        li4: "<p>Pre-cleaner options</p>",
        li5: "<p>Centrifugal pre-cleaner</p>",
        li6: "<p>Differential pressure gauge to correlate positive flow</p>",
        // li7: "<p>cTUV, CE, CSA Certified</p>",
        li7: "<p>Factory system integrity test</p>",
        li8: "<p>Factory system performance test</p>",
      },
    },
    section3: {
      h2: "FPS 55.2",
      ul: {
        li0:
          "<p>Smallest quantifying system available in the market, 10.5” x 10” x 18”</p>",
        li1: "<p>Heavy duty powder coated metal construction</p>",
        li2: "<p>12 volt or 24 volt DC</p>",
        li3: "<p>50 CFM</p>",
        li4: "<p>Centrifugal pre-cleaner</p>",
        li5: "<p>Differential pressure gauge to correlate positive flow</p>",
        li6:
          '<p><a class="anchor-link" href="https://www.tuv.com/usa/en/product-certification.html" target="_blank" rel="noopener noreferrer">cTUV</a>, <a class="anchor-link" href="https://ec.europa.eu/growth/single-market/ce-marking/" target="_blank" rel="noopener noreferrer">CE</a>,  <a class="anchor-link" href="https://www.csagroup.org/testing-certification/marks-labels/csa-marks-labels-north-america/" target="_blank" rel="noopener noreferrer">CSA</a> Certified</p>',
        li7: "<p>Factory system integrity test</p>",
        li8: "<p>Factory system performance test</p>",
        li9: "<p>Meets EN15695:category 4</p>",
      },
    },
    section4: {
      h2: "FPS 55.1-D",
      ul: {
        li0: "<p>Attaches to FPS55.2</p>",
        li1:
          "<p>Added unit for filtration with heavy concentrations or specific vapors</p>",
        li2: "<p>12 volt or 24 volt DC</p>",
        li3: "<p>115 volt AC</p>",
        li4: "<p>50 CFM</p>",
        li5: "<p>Factory system integrity test</p>",
        li6: "<p>Factory system performance test</p>",
      },
    },
    systemOperation: systemOperation.content,
    pressurization: pressurization.content,

    a1: `FPS Installation Instruction Manual`,
    a7: "FPS Basic Installations (pdf)",
    // a8: "FPS Basic Installations (pptx)",
    a2: `CAF Recommended Flow Rate`,
    a3: `Cab Pressure is Not An Indication of Cab Protection`,
    a4: `Protection Particulate System Filter Test Procedure`,
    a5: `Vapor Test Comparisons `,
    a6: `View FPS Filters`,
  },
}
