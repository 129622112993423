export default {
  slug: "performance-proven",
  url: "/caf-standard/performance-proven",
  title: "Performance Proven",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "Field Testing Service.",
      },
      p1: `At CAF, our testing and consulting services include:`,
      ul: [
        `Laboratory and field testing`,
        `Mobile Field Test on customer site`,
        `Digitally transmit data to CAF for efficiency test report`,
        `Service and products to a broad scope of industries with a global customer base`,
        `Mining, metal foundries, ethanol plants, landfills, and residential applications`,
        `Specific filtration solutions for controlled environments`,
        `Air filtration solutions based on patented technology`,
        `Correlation between particulate and vapor test data`,
        `CO2 Cab Integrity Test`,
      ],
      // p1: 'In October of 2015, three members of the CAF Testing Team went to France to assist a customer in installing and testing the FPS System on a crane. The customer required the information to provide to the government to ensure a safe environment for the operator.',
      p2:
        "Clean Air Filter’s testing team has the equipment and experience to travel to the customer site for cab integrity testing to aide operator protection.",
    },
    section2: {
      p: `Once a cab is field-tested for protection levels with Optical Particle Counters and/or CO2, the ratio can be used as an indication of cab performance. Once calibrated, the DPG will give signs of filter loading, cab seal degradation, electrical changes, improper filter installation, etc.`,
      h2: `Performance Proven`,
      ul: [
        `Infield / onsite testing is utilized to establish actual “Performance”.`,
        `Data is digitally collected and sent to Clean Air Filter for evaluation and reports.`,
        `Field testing is correlated to lab performance on particulate, vapor, and/or flow rates.`,
        `To optimize performance, actual cab or enclosure inspection is important to identify leaks and/or any other issues that require maintenance.`,
      ],
    },
    sectionFilterFitTest: {
      h2: "Fit Factor",
      p: `CAF experts use “respirator logic” as the fit factor for installation and usage of all products.`,
      a0: `Fit Factor and Protection Factor `,
      a1: `Cab Protection Factor Template`,
      a2: "ASABE X613 Cabin Filtration ",
    },
  },
}
