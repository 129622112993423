import React from "react";
import brandStyles from './brand.module.scss';
import cafStatement from './icons/Tag-DropDown-Logo-Shadow-01.png'

export default function (props) {

	return <svg 
	style={props.style} 
	className={props.show ? brandStyles.cafStatementShow : brandStyles.cafStatement} 
	version="1.1" 
	id="Layer_1" 
	xmlns="http://www.w3.org/2000/svg" 
	xlink="http://www.w3.org/1999/xlink" 
	x="0px" 
	y="0px"
	width="250" 
	// height="" 
	viewBox="118 -79 2262 420" 
	enableBackground="new 118 -79 2262 420" 
	space="preserve">
	<g>
		<image overflow="visible" width="2262" height="420" href={cafStatement}  transform="matrix(1 0 0 1 118 -79)"></image>
		<g>
			<g>
				<path fill="#FFFFFF" d="M591.875,128.197l12.007-25.765l12.018,25.765H591.875z M630.586,159.688h13.836l-32.34-69.354H597.37
				c-0.98,0.009-1.888,0.552-2.357,1.434l-31.666,67.922h13.839l8.835-18.949h35.729L630.586,159.688z"/>
				<path fill="#FFFFFF" d="M694.641,125.974c-5.894,0-10.097,4.095-10.097,10.724v22.812h-12.616v-44.16h7.884l1.789,6.52h0.627
				c2.316-4.311,6.521-6.52,12.304-6.52h2.417v10.625H694.641L694.641,125.974z"/>
				<path fill="#FFFFFF" d="M655.119,111.812c4.206,0,7.469-2.95,7.469-7.153c0-4.201-3.263-7.252-7.469-7.252
				c-4.2,0-7.36,3.051-7.36,7.252C647.759,108.861,650.919,111.812,655.119,111.812"/>
				<rect x="648.864" y="115.351" fill="#FFFFFF" width="12.618" height="44.154" />
				<path fill="#FFFFFF" d="M737.546,90.243c-1.467,0.009-2.678,1.22-2.678,2.712v66.576h12.534v-31.499H780.6
				c0.99-0.008,1.896-0.559,2.365-1.439l5.178-11.104h-40.74V102.79l43.582-0.009c0.98,0,1.896-0.554,2.367-1.442l5.175-11.096
				H737.546z"/>
				<path fill="#FFFFFF" d="M845.753,101.89h12.617v13.461h10.831v9.779H858.37v19.768c0,3.049,1.467,4.836,4.731,4.836
				c2.521,0,4.731-1.477,4.731-1.477l3.577,8.729c-1.896,1.369-6.312,3.568-11.357,3.568c-10.205,0-14.301-6.412-14.301-15.238
				L845.753,101.89L845.753,101.89z"/>
				<path fill="#FFFFFF" d="M887.696,132.277h17.663c-0.528-5.358-3.472-8.515-8.624-8.515
				C892.114,123.763,888.743,126.499,887.696,132.277 M898.84,160.769c-14.829,0-23.653-9.989-23.653-23.342
				c0-13.242,8.824-23.127,21.866-23.127c12.931,0,20.603,8.729,20.603,21.973c0,1.262-0.108,3.472-0.108,3.472h-30.172
				c0.739,7.565,5.258,11.035,12.307,11.035c6.938,0,11.354-3.675,11.354-3.675l4.413,8.298
				C915.448,155.402,910.086,160.769,898.84,160.769"/>
				<path fill="#FFFFFF" d="M950.225,125.974c-5.89,0-10.097,4.095-10.097,10.724v22.812h-12.616v-44.16h7.887l1.791,6.52h0.634
				c2.306-4.311,6.513-6.52,12.295-6.52h2.418v10.625H950.225L950.225,125.974z"/>
				<path fill="#FFFFFF" d="M805.484,111.812c4.203,0,7.46-2.95,7.46-7.153c0-4.201-3.257-7.252-7.46-7.252
				c-4.204,0-7.36,3.051-7.36,7.252C798.123,108.861,801.28,111.812,805.484,111.812"/>
				<rect x="799.229" y="115.351" fill="#FFFFFF" width="12.61" height="44.154" />
				<polygon fill="#FFFFFF" points="822.328,90.26 822.328,95.897 822.328,159.509 834.941,159.509 834.941,95.897 834.941,90.26 			
				"/>
				<path fill="#FFFFFF" d="M339.815,114.179c0.51,0.664,1.302,1.062,2.151,1.071h12.211c-4.985-14.721-18.738-24.727-34.57-24.727
				c-20.153,0-36.544,16.395-36.544,36.542c0,20.145,16.391,36.537,36.544,36.537c15.832,0,29.585-10.004,34.57-24.726h-12.211
				c-0.909,0-1.756,0.463-2.26,1.229l-0.459,0.676c-4.51,6.445-11.787,10.27-19.641,10.27c-13.23,0-23.993-10.754-23.993-23.984
				c0-13.229,10.763-23.992,23.993-23.992c7.836,0,15.096,3.809,19.605,10.229L339.815,114.179z"/>
				<path fill="#FFFFFF" d="M397.103,132.277h17.662c-0.527-5.358-3.469-8.515-8.62-8.515
				C401.512,123.763,398.149,126.499,397.103,132.277 M408.245,160.769c-14.825,0-23.653-9.989-23.653-23.342
				c0-13.242,8.828-23.127,21.866-23.127c12.93,0,20.604,8.729,20.604,21.973c0,1.262-0.108,3.472-0.108,3.472H396.78
				c0.745,7.565,5.261,11.035,12.308,11.035c6.938,0,11.355-3.675,11.355-3.675l4.41,8.298
				C424.853,155.402,419.497,160.769,408.245,160.769"/>
				<path fill="#FFFFFF" d="M462.255,143.948v-3.58c-1.163-0.624-3.481-1.364-7.577-1.364c-6.302,0-9.665,2.527-9.665,6.733
				c0,3.675,2.941,6.197,7.564,6.197C457.838,151.935,462.038,148.677,462.255,143.948 M465.716,159.509l-1.682-5.57h-0.52
				c-2.423,3.887-7.047,6.615-14.201,6.615c-10.193,0-16.187-5.99-16.187-13.976c0-8.938,6.726-14.506,20.078-14.506
				c3.79,0,6.841,0.518,9.05,1.151v-1.365c0-5.465-3.892-7.471-10.519-7.471c-6.41,0-11.562,3.157-11.562,3.157l-3.996-9.253
				c0,0,6.411-3.992,17.867-3.992c13.36,0,20.29,7.254,20.29,17.975v27.234H465.716L465.716,159.509z"/>
				<path fill="#FFFFFF" d="M484.244,159.509v-44.158h7.887l1.778,6.205h0.744c3.362-4.947,7.251-7.359,14.291-7.359
				c11.354,0,18.403,7.779,18.403,19.658v25.654h-12.619v-24.603c0-5.367-3.254-9.677-8.933-9.677c-5.784,0-8.933,4.633-8.933,9.041
				v25.238H484.244L484.244,159.509z"/>
				<polygon fill="#FFFFFF" points="363.847,90.26 363.847,95.897 363.847,159.509 376.465,159.509 376.465,95.897 376.465,90.26 			
				"/>
				<path fill="#FFFFFF" d="M1037.241,86.073h-10.35c-1.476,0.009-2.754,0.854-3.38,2.085l-46.271,87.446h12.75L1037.241,86.073z" />
				<path fill="#FFFFFF" d="M1056.464,86.073h-10.354c-1.476,0.009-2.744,0.854-3.371,2.085l-46.276,87.446h12.75L1056.464,86.073z"
				/>
				<path fill="#FFFFFF" d="M1119.443,133.118c0-8.963-5.389-16.023-14.67-16.023c-11.422,0-19.666,9.836-19.666,21.338
				c0,8.961,5.314,15.94,14.432,15.94C1111.041,154.373,1119.443,144.693,1119.443,133.118 M1078.287,139.224
				c0-15.463,11.576-28.311,27.359-28.311c13.006,0,20.623,9.357,20.623,21.49c0,11.183-6.189,20.861-15.785,25.537l4.994,10.947
				h-7.373l-3.967-8.804c-1.742,0.317-3.57,0.554-5.473,0.554C1085.82,160.64,1078.287,151.199,1078.287,139.224"/>
				<path fill="#FFFFFF" d="M1165.654,126.218l-4.68,33.312h-4.281l-0.475-4.681h-0.238c-0.953,1.268-4.682,5.474-12.215,5.474
				c-8.727,0-12.689-6.106-11.422-15.147l2.695-18.955h6.424l-2.535,18.238c-0.875,5.948,1.188,10.393,6.977,10.393
				c6.426,0,9.912-5.313,10.471-9.041l2.773-19.59h6.506V126.218z"/>
				<path fill="#FFFFFF" d="M1189.896,130.815c-6.498,0-12.129,5.873-12.129,13.878c0,6.032,3.568,10.233,8.803,10.233
				c6.582,0,12.371-5.393,12.371-13.959C1198.941,134.622,1195.133,130.815,1189.896,130.815 M1202.67,126.218h4.439l-4.678,33.312
				h-4.443l-0.158-5.155h-0.238c0,0-3.883,5.948-13.006,5.948c-7.85,0-13.402-5.79-13.402-14.831
				c0-11.737,8.484-20.064,18.08-20.064c9.361,0,11.502,5.947,11.502,5.947h0.236L1202.67,126.218z"/>
				<polygon fill="#FFFFFF" points="1225.961,111.545 1219.221,159.528 1212.713,159.528 1219.459,111.545 			" />
				<path fill="#FFFFFF" d="M1234.914,115.826c0-2.536,2.299-4.599,4.838-4.599c2.143,0,3.646,1.586,3.646,3.648
				c0,2.538-2.219,4.518-4.836,4.518C1236.5,119.394,1234.914,117.892,1234.914,115.826 M1240.861,126.218l-4.678,33.312h-6.43
				l4.684-33.312H1240.861z"/>
				<path fill="#FFFFFF" d="M1254.809,116.065h6.506l-1.428,10.148h9.518l-0.713,5.158h-9.52l-2.617,18.396
				c-0.477,3.256,0.793,5,3.967,5c2.299,0,4.758-1.43,4.758-1.43l1.352,4.602c-0.402,0.316-3.65,2.38-7.852,2.38
				c-7.062,0-9.602-4.126-8.729-10.472l2.617-18.478h-6.104l0.713-5.158h6.107L1254.809,116.065z"/>
				<path fill="#FFFFFF" d="M1302.381,158.415c-1.271,8.883-7.297,15.309-17.686,15.309c-8.885,0-12.215-4.6-12.611-5.155l3.965-4.2
				c0.32,0.238,3.094,4.044,9.203,4.044c6.264,0,9.752-3.567,10.707-9.835l0.395-2.538c-1.742,1.745-5.232,4.283-10.709,4.283
				c-9.273,0-13.242-6.188-11.975-15.229l2.619-18.877h6.502l-2.539,18.242c-0.789,5.868,1.191,10.389,6.979,10.389
				c6.268,0,9.762-4.996,10.475-8.802l2.773-19.829h6.422L1302.381,158.415z"/>
				<polygon fill="#FFFFFF" points="1368.887,111.943 1368.018,117.89 1352.553,117.89 1346.682,159.528 1340.178,159.528 
				1346.047,117.89 1330.66,117.89 1331.537,111.943 			"/>
				<path fill="#FFFFFF" d="M1368.4,138.906h17.523c0-5.312-2.459-8.168-7.297-8.168S1370.145,133.435,1368.4,138.906
				 M1375.773,160.402c-9.518,0-14.908-6.029-14.908-14.832c0-11.263,7.852-20.146,18.557-20.146
				c9.676,0,13.959,7.137,12.609,16.576l-0.236,1.664h-24.35c-0.793,7.614,3.254,11.342,9.518,11.342
				c5.869,0,9.598-3.013,9.598-3.013l1.742,4.519C1388.301,156.515,1384.1,160.402,1375.773,160.402"/>
				<path fill="#FFFFFF" d="M1409.541,155.088c5.629,0,7.613-2.224,7.613-4.838c0-6.106-18.641-3.569-18.641-14.119
				c0-5.788,4.604-10.709,14.039-10.709c8.725,0,13.086,2.778,13.086,2.778l-3.17,5.234c0,0-3.807-2.776-10.076-2.776
				c-5.076,0-7.531,1.664-7.531,4.521c0,5.871,18.715,2.778,18.715,13.961c0,5.709-4.119,11.183-14.75,11.183
				c-9.6,0-13.242-4.045-13.242-4.045l3.25-4.442C1398.834,151.834,1402.957,155.088,1409.541,155.088"/>
				<path fill="#FFFFFF" d="M1438.311,116.065h6.504l-1.43,10.148h9.521l-0.721,5.158h-9.516l-2.617,18.396
				c-0.477,3.256,0.795,5,3.971,5c2.299,0,4.758-1.43,4.758-1.43l1.348,4.602c-0.398,0.316-3.65,2.38-7.852,2.38
				c-7.061,0-9.602-4.126-8.725-10.472l2.617-18.478h-6.107l0.713-5.158h6.109L1438.311,116.065z"/>
				<path fill="#FFFFFF" d="M1463.121,138.906h17.525c0-5.312-2.461-8.168-7.299-8.168
				C1468.512,130.738,1464.865,133.435,1463.121,138.906 M1470.494,160.402c-9.518,0-14.91-6.029-14.91-14.832
				c0-11.263,7.852-20.146,18.557-20.146c9.68,0,13.961,7.137,12.611,16.576l-0.24,1.664h-24.348
				c-0.789,7.614,3.258,11.342,9.518,11.342c5.873,0,9.598-3.013,9.598-3.013l1.744,4.519
				C1483.023,156.515,1478.822,160.402,1470.494,160.402"/>
				<path fill="#FFFFFF" d="M1510.764,130.815c-6.502,0-12.131,5.873-12.131,13.878c0,6.032,3.568,10.233,8.801,10.233
				c6.582,0,12.369-5.393,12.369-13.959C1519.803,134.622,1515.998,130.815,1510.764,130.815 M1523.611,111.545h6.426l-6.74,47.983
				h-4.441l-0.156-5.155h-0.238c0,0-3.889,5.948-13.01,5.948c-7.852,0-13.402-5.79-13.402-14.831
				c0-11.737,8.486-20.065,18.16-20.065c8.568,0,10.473,4.996,10.473,4.996H1521L1523.611,111.545z"/>
				<path fill="#FFFFFF" d="M1532.711,156.672c0-2.536,2.219-4.602,4.758-4.602c2.143,0,3.646,1.59,3.646,3.649
				c0,2.539-2.301,4.602-4.836,4.602C1534.217,160.321,1532.711,158.735,1532.711,156.672"/>
				<path fill="#FFFFFF" d="M1592.963,117.89h-12.293l-2.855,20.385h12.293c8.01,0,13.322-3.651,13.322-11.818
				C1603.43,120.667,1599.305,117.89,1592.963,117.89 M1610.172,125.505c0,11.896-8.486,18.717-20.938,18.717h-12.295l-2.139,15.308
				h-6.506l6.662-47.585h19.115C1604.225,111.943,1610.172,116.937,1610.172,125.505"/>
				<path fill="#FFFFFF" d="M1619.191,138.906h17.529c0-5.312-2.461-8.168-7.299-8.168S1620.936,133.435,1619.191,138.906
				 M1626.568,160.402c-9.518,0-14.91-6.029-14.91-14.832c0-11.263,7.852-20.146,18.559-20.146c9.676,0,13.957,7.137,12.609,16.576
				l-0.238,1.664h-24.35c-0.795,7.614,3.252,11.342,9.52,11.342c5.871,0,9.596-3.013,9.596-3.013l1.746,4.519
				C1639.098,156.515,1634.895,160.402,1626.568,160.402"/>
				<path fill="#FFFFFF" d="M1667.074,131.454c-4.68,0-9.121,3.809-9.836,9.039l-2.693,19.035h-6.502l4.678-33.311h4.359
				l-0.074,5.631h0.393c2.145-3.651,5.951-5.709,10.391-5.709h1.824l-0.713,5.313L1667.074,131.454L1667.074,131.454z"/>
				<path fill="#FFFFFF" d="M1680.07,159.528h-6.504l3.967-28.155h-6.109l0.715-5.158h6.107l0.475-3.567
				c0.951-7.217,5.951-11.736,13.244-11.736c4.602,0,6.98,1.587,6.98,1.587l-2.697,5.077c0,0-1.902-1.032-4.68-1.032
				c-3.328,0-5.709,2.144-6.266,5.868l-0.555,3.805h9.596l-0.713,5.158h-9.6L1680.07,159.528z"/>
				<path fill="#FFFFFF" d="M1722.646,141.05c0-6.347-3.805-10.392-8.883-10.392c-6.506,0-12.451,5.635-12.451,14.198
				c0,6.424,3.805,10.23,8.957,10.23C1716.697,155.088,1722.646,149.693,1722.646,141.05 M1709.557,160.321
				c-8.48,0-14.67-5.713-14.67-14.909c0-11.899,9.279-19.987,19.59-19.987c8.41,0,14.67,6.029,14.67,15.225
				C1729.146,152.628,1719.791,160.321,1709.557,160.321"/>
				<path fill="#FFFFFF" d="M1753.555,131.454c-4.68,0-9.119,3.809-9.836,9.039l-2.697,19.035h-6.5l4.678-33.311h4.361l-0.076,5.631
				h0.396c2.141-3.651,5.949-5.709,10.387-5.709h1.822l-0.711,5.313L1753.555,131.454L1753.555,131.454z"/>
				<path fill="#FFFFFF" d="M1766.469,126.218v4.836h0.477c3.094-3.726,6.344-5.629,11.977-5.629c5.551,0,9.357,2.381,11.025,6.186
				h0.396c2.852-3.725,6.422-6.186,12.688-6.186c8.646,0,12.85,6.104,11.58,15.151l-2.697,18.952h-6.422l2.537-18.239
				c0.793-5.871-1.43-10.392-7.221-10.392c-6.027,0-9.197,4.602-9.992,8.484l-2.854,20.146h-6.506l2.699-19.354
				c0.713-5.313-1.668-9.277-7.457-9.277c-5.945,0-9.197,4.838-9.912,8.484l-2.854,20.146h-6.504l4.676-33.311H1766.469
				L1766.469,126.218z"/>
				<path fill="#FFFFFF" d="M1840.428,130.815c-6.5,0-12.131,5.873-12.131,13.878c0,6.032,3.566,10.233,8.801,10.233
				c6.584,0,12.375-5.393,12.375-13.959C1849.473,134.622,1845.664,130.815,1840.428,130.815 M1853.199,126.218h4.439l-4.678,33.312
				h-4.441l-0.158-5.155h-0.24c0,0-3.883,5.948-13.006,5.948c-7.85,0-13.4-5.79-13.4-14.831c0-11.737,8.48-20.064,18.078-20.064
				c9.361,0,11.502,5.947,11.502,5.947h0.236L1853.199,126.218z"/>
				<path fill="#FFFFFF" d="M1872.131,126.218v4.836h0.477c2.855-3.568,6.346-5.629,12.215-5.629c8.723,0,13.008,6.104,11.736,15.151
				l-2.695,18.952h-6.424l2.537-18.239c0.795-5.871-1.588-10.392-7.375-10.392c-6.107,0-9.6,5.078-10.234,8.803l-2.771,19.828
				h-6.502l4.676-33.311H1872.131L1872.131,126.218z"/>
				<path fill="#FFFFFF" d="M1903.594,145.412c0-11.503,8.404-19.987,19.508-19.987c7.377,0,9.836,2.934,9.836,2.934l-2.617,4.84
				c0,0-2.854-2.381-7.609-2.381c-6.668,0-12.455,5.313-12.455,14.119c0,6.423,3.809,9.99,9.119,9.99
				c4.762,0,8.248-2.377,8.248-2.377l1.033,4.758c0,0-3.174,3.015-10.473,3.015C1909.225,160.321,1903.594,154.295,1903.594,145.412
				"/>
				<path fill="#FFFFFF" d="M1942.438,138.906h17.527c0-5.312-2.461-8.168-7.297-8.168
				C1947.83,130.738,1944.182,133.435,1942.438,138.906 M1949.814,160.402c-9.518,0-14.912-6.029-14.912-14.832
				c0-11.263,7.852-20.146,18.559-20.146c9.678,0,13.959,7.137,12.613,16.576l-0.244,1.664h-24.346
				c-0.791,7.614,3.252,11.342,9.521,11.342c5.867,0,9.592-3.013,9.592-3.013l1.746,4.519
				C1962.342,156.515,1958.141,160.402,1949.814,160.402"/>
				<path fill="#FFFFFF" d="M2014.578,117.89h-12.293l-2.855,20.385h12.293c8.01,0,13.326-3.651,13.326-11.818
				C2025.049,120.667,2020.924,117.89,2014.578,117.89 M2031.789,125.505c0,11.896-8.486,18.717-20.941,18.717h-12.289
				l-2.141,15.308h-6.506l6.66-47.585h19.113C2025.842,111.943,2031.789,116.937,2031.789,125.505"/>
				<path fill="#FFFFFF" d="M2053.02,131.454c-4.682,0-9.119,3.809-9.836,9.039l-2.697,19.035h-6.502l4.684-33.311h4.355
				l-0.074,5.631h0.393c2.145-3.651,5.951-5.709,10.391-5.709h1.824l-0.713,5.313L2053.02,131.454L2053.02,131.454z"/>
				<path fill="#FFFFFF" d="M2083.781,141.05c0-6.347-3.807-10.392-8.885-10.392c-6.506,0-12.451,5.635-12.451,14.198
				c0,6.424,3.807,10.23,8.961,10.23C2077.832,155.088,2083.781,149.693,2083.781,141.05 M2070.693,160.321
				c-8.482,0-14.672-5.713-14.672-14.909c0-11.899,9.277-19.987,19.588-19.987c8.41,0,14.674,6.029,14.674,15.225
				C2090.283,152.628,2080.924,160.321,2070.693,160.321"/>
				<path fill="#FFFFFF" d="M2105.412,159.528l-10.469-33.313h7.137l6.268,23.398c0.154,0.712,0.32,1.664,0.154,2.302h0.4
				c0.156-0.638,0.557-1.59,0.953-2.302l12.844-23.398h7.061l-19.826,33.313H2105.412z"/>
				<path fill="#FFFFFF" d="M2137.279,138.906h17.525c0-5.312-2.457-8.168-7.295-8.168
				C2142.67,130.738,2139.021,133.435,2137.279,138.906 M2144.654,160.402c-9.518,0-14.91-6.029-14.91-14.832
				c0-11.263,7.85-20.146,18.557-20.146c9.678,0,13.961,7.137,12.613,16.576l-0.24,1.664h-24.346
				c-0.797,7.614,3.25,11.342,9.518,11.342c5.869,0,9.594-3.013,9.594-3.013l1.744,4.519
				C2157.182,156.515,2152.982,160.402,2144.654,160.402"/>
				<path fill="#FFFFFF" d="M2175.168,126.218v4.836h0.479c2.855-3.568,6.342-5.629,12.209-5.629c8.73,0,13.01,6.104,11.74,15.151
				l-2.695,18.952h-6.424l2.535-18.239c0.797-5.871-1.584-10.392-7.373-10.392c-6.111,0-9.6,5.078-10.234,8.803l-2.773,19.828h-6.5
				l4.68-33.311H2175.168L2175.168,126.218z"/>
				<path fill="#FFFFFF" d="M2205.918,156.672c0-2.536,2.221-4.602,4.758-4.602c2.143,0,3.648,1.59,3.648,3.649
				c0,2.539-2.299,4.602-4.84,4.602C2207.426,160.321,2205.918,158.735,2205.918,156.672"/>
			</g>
		</g>
	</g>
</svg >;
}