import React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import calloutStyles from '../callOut/callout.module.scss';
import sideCallOutStyles from './sideCallOut.module.scss';
import SocialBar from '../socialBar';
import navItems from '../../data/navItems'

export default function About() {
    const { cta1, cta2, cta3, cta4, cta5 } = useStaticQuery(
      graphql`
        query sideCallOuts {
          cta1: file(relativePath: { eq: "SecondaryCTA-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
              fixed(width: 250) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
          cta2: file(relativePath: { eq: "SecondaryCTA-2.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
              fixed(width: 250) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
          cta5: file(relativePath: { eq: "SecondaryCTA-3.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
              fixed(width: 250) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
          cta4: file(relativePath: { eq: "SecondaryCTA-4.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
              fixed(width: 250) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      `
    )

    const imageStyle = {
        borderRadius: '55px 0',
        boxShadow: '3px 3px 10px 6px #ccc'
    };
    const imageImgStyle = {
        borderRadius: '55px 0',
    };

    const linkStyle = {
        marginTop: '0.5rem'
    }

    return (
      <div className={sideCallOutStyles.wrapper}>
        {
          [
            // <div
            //     className={calloutStyles.wrapper}
            //     key={1}
            // >
            //     <Img
            //         style={imageStyle}
            //         imgStyle={imageImgStyle}
            //         fixed={cta1.childImageSharp.fixed}>
            //     </Img>

            //     <div className={calloutStyles.overlaySide}>
            //         <span className={calloutStyles.white}>Catch up</span>
            //         <span className={calloutStyles.white}>with the</span>
            //         <span className={calloutStyles.white}>latest news.</span>
            //         <br></br>
            //         <Link style={linkStyle} className="primary red" to="/newsroom">Newsroom</Link>
            //     </div>
            // </div>,
            <div className={calloutStyles.wrapper} key={2}>
              <Img
                style={imageStyle}
                imgStyle={imageImgStyle}
                fixed={cta2.childImageSharp.fixed}
              ></Img>
              <div className={calloutStyles.overlaySide}>
                <SocialBar className={calloutStyles.socialBar}></SocialBar>
                <span className={calloutStyles.white}>Join the</span>
                <span className={calloutStyles.white}>conversation</span>
                <br></br>
                <Link style={linkStyle} className="primary white" to="/contact">
                  Contact
                </Link>
              </div>
            </div>,
            <div className={calloutStyles.wrapper} key={3}>
              <Img
                style={imageStyle}
                imgStyle={imageImgStyle}
                fixed={cta5.childImageSharp.fixed}
              ></Img>
              <div className={calloutStyles.overlaySide}>
                <span className={calloutStyles.red}>Need CAF</span>
                <span className={calloutStyles.red}>Technical</span>
                <span className={calloutStyles.red}>Assistance?</span>
                <br></br>
                <Link style={linkStyle} className="primary red" to="/contact">
                  Contact Us
                </Link>
              </div>
            </div>,
            <div className={calloutStyles.wrapper} key={4}>
              <Img
                style={imageStyle}
                imgStyle={imageImgStyle}
                fixed={cta4.childImageSharp.fixed}
              ></Img>
              <div className={calloutStyles.overlaySide}>
                <span className={calloutStyles.white}>See the</span>
                <span className={calloutStyles.white}>results for</span>
                <span className={calloutStyles.white}>yourself.</span>
                <br></br>
                <Link
                  style={linkStyle}
                  className="primary red"
                  to={navItems[1].to}
                >
                  Quality Tested
                </Link>
              </div>
            </div>,
          ][Math.floor(Math.random() * 3)]
        }
      </div>
    )
}


