import React from "react"
import SocialBar from "../socialBar"
import footerStyles from "./footer.module.scss"
import { Link } from "gatsby"
import CafLogoSvg from "../sharedResources/Footer-Logo.svg"
import CafStatementSvg from "../sharedResources/Footer-Logo2.svg"
import CafStatementSvgMobile from "../sharedResources/Footer-Logo2-mobile.svg"

export default function Footer(props) {
  return (
    <footer style={{ textAlign: "center" }}>
      {/* <section>
            <SocialBar className={footerStyles.social}></SocialBar>
            <nav className={footerStyles.nav}>
                {props.navItems.map((item, index) => <Link
                    key={index}
                    className={footerStyles.navItem}
                    to={item.to}>{item.label}</Link>)}
            </nav>
        </section> */}
      <div className={footerStyles.brand}>
        <div>
          <img
            className={footerStyles.brandLogo}
            src={CafLogoSvg}
            alt="coming soon"
          ></img>
          <img
            className={footerStyles.brandDesktop}
            src={CafStatementSvg}
            alt="coming soon"
          ></img>
          <img
            className={footerStyles.brandMobile}
            src={CafStatementSvgMobile}
            alt="coming soon"
          ></img>
        </div>
        <address>
          <span>2203 HWY 59</span>
          <span>Po Box 212</span>
          <span>Defiance, IA&nbsp;&nbsp;51527</span>
          <span>712 748 3642</span>
        </address>
      </div>
      <section></section>
    </footer>
  )
}
