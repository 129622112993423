export default {
  slug: "products",
  url: "/products",
  title: "Products",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "Air Filtration Solutions.",
      },
      p1:
        "We hold ourselves to a higher standard and refuse to settle for anything less than exceptional. We will effectively design exceptional air filtration solutions that you can depend on to keep you healthy and safe for years to come.",
      p2:
        "Every one of our products is built right, every time. And through a constant cycle of rigorous, methodical, painstaking testing and evaluations we reinforce our dedication to quality, because that’s what keeps our customers safe, healthy, and satisfied year after year.",
      p3:
        "CAF is in a unique position to be able to completely characterize enclosed environmental enclosures with accepted particulate and vapor test methodologies. In addition, CAF utilizes a patented CO2 Cab Integrity Test. CAF’s internal standards meet or exceed approved European Standard EN15695 which addresses filter and cab performance. Specific Cab Protection Levels are a balance between “cab integrity” and “filter efficiency”.",
      p4:
        "Specific Cab Protection Levels are a balance between “cab integrity” and “filter efficiency”.",
    },
    section2: {
      h2: "Cabin Air Filters",
      p1:
        "Clean Air Filter provides particulate/vapor cabin filters featuring a unique patented 3-in-1 design.",
      a: "View Cabin Air Filters",
    },
    section3: {
      h2: "Filtration Protection Systems",
      p1:
        "Our mobile and stationary FPS units are designed to create a positive flow of clean filtered air into your environment.",
      a: `View Filtration Protection Systems`,
    },
    section4: {
      h2: "Air Testing Services",
      p1:
        "CAF is in a unique position to be able to completely characterize environmental enclosures with accepted particulate and vapor test methodologies. In addition, CAF utilizes a patented Cab Integrity Test.",
      a: `View Air Testing Services`,
    },
  },
}
