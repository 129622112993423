export default {
  slug: "about",
  url: "/about",
  title: "About",
  content: {
    section1: {
      h1: {
        head: "About",
        tail: "Clean Air Filter, Co.",
      },
      a1: "Contact CAF",
      a2: "Become a Distributor",
      p1:
        "Established in 1990 to offer farmers a solution to their dusty chemical environment.",
      ul: {
        li1:
          "<p>Industry Leader with patented filter design (3-in-1 vapor/particulate)</p>",
        li2: "<p>First to design/testing/evaluation</p>",
        li3:
          "<p>Teamed with NIOSH, MSHA, and Cal-EPA (add supporting documents)</p>",
        li4: "<p>Developed ASAE S525 Series filters</p>",
        li5: "<p>CAF Test Chamber created in 1994</p>",
        li6: "<p>Developed Integrity test on cabs</p>",
        li7: "<p>Leader in system-level vapor testing/analysis</p>",
        li8: "<p>Cal-EPA Approved testing</p>",
        li9: "<p>Tested to meet EN15695 standard, and became certified</p>",
      },
    },
    section2: {
      h2: "Services",
      ul: {
        li1:
          "<p>Service and products to a broad scope of industries with a global customer base</p>",
        li2:
          "<p>Mining, metal foundries, ethanol plants, landfills, and residential applications</p>",
        li3: "<p>Specific filtration solutions for controlled environments</p>",
        li4: "<p>Air filtration solutions based on patented technology</p>",
        li5:
          "<p>Laboratory and field testing, with correlation between particulate and vapor test data</p>",
        li6: "<p>Patented CO2 Cab Integrity Test</p>",
        li7: "<p>Cal-EPA Approved</p>",
      },
    },
    section3: {
      h2: "History",
      p1: `In 1990, the first filters were developed to protect operators’ cab environment in agricultural equipment from extreme dust, chemical, and pesticide application exposure. We soon became the American farmer's choice, still supplying some of the largest spray equipment manufacturers with their OEM cab filters.`,
      p2: `Soon to follow, CAF was invited by NIOSH to participate in the Silicosis Project. At this time, the first Filtration Protection System (FPS) unit was developed, and HEPA media was added to the filtration for increased protection. It was a worldwide endeavor by NIOSH, and this product demonstrated optimum performance.`,
      p3: `Currently, CAF prides itself on manufacturing the best 50 CFM (84.95 m3/h) and 150 CFM (254.85 m3/h) FPS in the world. The system is field-tested with multiple contaminants proving a great deal of variability unmatched in both particulate and vapor protection within the same system.`,
      p4: `The FPS 55 system was developed for 55 CFM (93.45 m3/h), ± 5 CFM (8.50 m3/h), with a new filter. The FPS unit is built of heavy-duty, powder-coated steel. The blower motor (a high-efficiency motor) uses a backward curve fan for steady performance under extreme dust loading, enabling the system to operate between 60 CFM (101.94 m3/h) and 35 CFM (59.47 m3/h).`,
      p5: `Each unit is factory tested with CO2 at 0.00038 micron enabling enclosures with the FPS55 Series to be field vapor tested for cab integrity.`,
      a: `<a class="download-link" href="https://www.cdc.gov/niosh/mining/UserFiles/works/pdfs/2012-180.pdf" target="_blank" rel="noopener noreferrer">Quantifying Unfiltered Air Leakage into Enclosed Cabs</a>`,
    },
  },
}
