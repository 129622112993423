import systemOperation from "./system-operation"
import pressurization from "./pressurization"
export default {
  slug: "fps955",
  url: "/products/filtration-protection-systems/fps955",
  title: "FPS 955",
  content: {
    section1: {
      h1: {
        head: "Proven",
        tail: "FPS 955 Series.",
      },
      p1:
        "The FPS-955 has proven itself in extreme environments such as overhead cranes in foundries, electronic control rooms in high dust environments of grain handling, and residential applications. This unit is designed for commercial and industrial environments such as mining and manufacturing.",
      section9952: {
        h2: `FPS 955.2`,
        ul: {
          li1: "<p>Heavy duty powder-coated metal construction</p>",
          li2: "<p>115 volt and 220 volt AC</p>",
          li3: "<p>150 CFM</p>",
          li4: "<p>Dual Inlets allows for fresh air and recirculation</p>",
          li5: "<p>Centrifugal pre-cleaner</p>",
          li6: "<p>Factory system performance test</p>",
          li7:
            '<p><a class="anchor-link" href="https://www.tuv.com/usa/en/product-certification.html" target="_blank" rel="noopener noreferrer">cTUV</a>, <a class="anchor-link" href="https://ec.europa.eu/growth/single-market/ce-marking/" target="_blank" rel="noopener noreferrer">CE</a>,  <a class="anchor-link" href="https://www.csagroup.org/testing-certification/marks-labels/csa-marks-labels-north-america/" target="_blank" rel="noopener noreferrer">CSA</a> Certified</p>',
        },
      },
      section9951: {
        h2: `FPS 955.1`,
        ul: {
          li1: "<p>Heavy duty powder-coated metal construction</p>",
          li2: "<p>115 volt and 220 volt AC</p>",
          li3: "<p>150 CFM</p>",
          li4: "<p>Dual Inlets allows for fresh air and recirculation</p>",
          li5: "<p>Centrifugal pre-cleaner</p>",
          li6: "<p>Factory system performance test</p>",
        },
      },
      p2: "CAF has proven technology with over 30 years of experience.",
    },
    systemOperation: `<section>
            <h2>System Operation</h2>

            <p>Airflow through the CAF FPS 955 Series system, with a CAF950R filter, is as follows:</p>
            <p>1. Contaminated air is drawn into the system through the pre-cleaner. The circular motion of the pre-cleaner separates out larger particulates, dramatically increasing filter life. The second
inlet can be used for recirculation or closed off to increase positive flow, or CFM, of clean air.</p>
            <p>2. Air passes through the three-stage primary filter.</p>
            <ul style="list-style: none;">
                <li>
                    <p><span class="stage-bullet">Stage 1</span> is a standard <em>PAPER</em>  media designed for the heavy dust environment of agricultural, industrial, mining, and off-road conditions. Also, the paper is superior in absorbing liquid aerosol droplets that are present in the air.</p>
                </li>
                <li>
                    <p><span class="stage-bullet">Stage 2</span> is respirator-grade <em>ACTIVATED CARBON</em>. The porous nature of this media effectively adsorbs organic vapors, such as diesel and chemical fumes. CAF’s customized, refined activated carbon works on surface area, and our patented design maximizes this principle.</p>
                </li>
                <li>
                    <p><span class="stage-bullet">Stage 3</span> is a sub-micron <em>FINAL FILTER</em>  that provides protection of up to 99.998 @ 0.3µm while allowing maximum airflow.</p>
                </li>
            </ul>
            <p>3. Air exiting the FPS unit creates a positive flow inside a properly sealed enclosure. This positive flow pushes clean air out of the leaks in the micro-environment side of the system, rather than allowing contaminated air to enter. A differential pressure gage is available to measure the difference in pressure between the inside and outside of the enclosure, which can be correlated to flow rates and filter life.</p>
        </section>`,

    pressurization: `<section>
            <p><span class="bolded">CAUTION:</span> <em>The enclosure MUST have positive airflow, greater than 18 Cubic Feet per Minute (CFM)</em>.</p> 
        </section>`,

    a1: `FPS Installation Instruction Manual`,
    a2: `CAF Recommended Flow Rate`,
    a3: `Cab Pressure is Not An Indication of Cab Protection`,
    a4: `Protection Particulate System Filter Test Procedure`,
    a5: `Vapor Test Comparisons `,
    a6: `View FPS Filters`,
  },
}
