import React, { useState } from "react"
import { Link } from "gatsby"
import dropdownStyles from "./dropdown.module.scss"
import navLinkStyles from "../styles/navLink.module.scss"
import { ReactComponent as Arrow } from "../../styles/icons/arrow-red.svg"
import GrandChildren from "./subItem"

export default function DropDownMenu(props) {
  const [open, setOpen] = useState(false)
  const [childrenOpen, setChildrenOpen] = useState(props.expanded)
  const [grandChildrenOpen, setGranchildrenOpen] = useState(false)
  const [expanded, setExpanded] = useState(null)
  const styleArrow = {
    transition: "0.1s",
    cursor: "pointer",
    position: "absolute",
    right: "100%",
    top: "0",
    transform: props.expanded ? "rotate(90deg)" : "rotate(0deg)",
  }

  const toggleChildren = () => {
    setChildrenOpen(!childrenOpen)
  }

  return (
    <div
      className={dropdownStyles.wrapper}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div
        style={props.width ? { width: props.width } : null}
        className={navLinkStyles.wrapper}
      >
        <Link
          to={props.to}
          activeClassName={navLinkStyles.active}
          partiallyActive={props.partiallyActive}
        >
          {props.label}
        </Link>
        <Arrow
          style={styleArrow}
          onClick={() => {
            props.setExpandingItem(props.expanded ? null : props.index)
          }}
        ></Arrow>
      </div>
      <GrandChildrenMenu
        to={props.to}
        childrenOpen={props.expanded}
        open={open}
        children={props.children}
        partiallyActive={props.partiallyActive}
        expanded={expanded}
        setExpanded={setExpanded}
      ></GrandChildrenMenu>
    </div>
  )
}

function GrandChildrenMenu(props) {
  return (
    <ul
      className={`${
        props.open ? dropdownStyles.childrenOpen : dropdownStyles.children
      } + ${
        props.childrenOpen
          ? dropdownStyles.childrenMobileOpen
          : dropdownStyles.childrenMobile
      }`}
    >
      {props.children.map((child, index) => {
        return (
          <GrandChildren
            partiallyActive={props.partiallyActive}
            child={child}
            key={index}
            to={props.to}
            expanded={props.expanded === index}
            setExpanded={props.setExpanded}
            index={index}
          ></GrandChildren>
        )
      })}
    </ul>
  )
}
