import about from './about';
import airFilters from './air-filters';
import airTestingServices from './air-testing-services';
import fps from './fps'
import fpsFilters from './fps-filters'
import fps551 from './fps551';
import fps955 from './fps955';
import limitation from "./limitations"
import maintenance from "./maintenance"
import products from './products';
import rd from './rd';
import resources from './resources';
import tetimonials from './testimonials'
import testing from './testing';
import tsc from "./testing-service-consulting"
import warranty from "./warranty"

export default {
  airTestingServices,
  about,
  airFilters,
  fps,
  fpsFilters,
  fps551,
  fps955,
  limitation,
  maintenance,
  products,
  rd,
  resources,
  tetimonials,
  testing,
  tsc,
  warranty,
}