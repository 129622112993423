export default {
  slug: "rd",
  url: "/rd",
  title: "Research & Development",
  content: {
    section1: {
      h1: {
        head: "Advanced",
        tail: "Research & Development.",
      },
      p1:
        "The Clean Air Filter team is committed to ongoing research and development – beyond any industry standard. Our R & D department continues to develop new laboratory test procedures, as well as field testing protocols for various industries of focus, including agriculture, mining, foundries, and landfills.",
      p2: `We take pride in providing and introducing particulate and vapor test methodologies that exceed standard.`,
      p3: `Clean Air Filter has developed particulate/vapor filters with excellent organic vapor adsorption capabilities. The objective is to offer the operator of the cab the cleanest temperature controlled work environment possible.`,
      p4: `Operator Protection Level Parameters and Field Testing Criteria used by CAF:`,
      ol: [
        `Operator Protection Level testing is based on total cab efficiency @ 0.3µm`,
        `Providing the operator with a minimum of 17.66 CFM (30 m³/h) of clean air at all times`,
        `Reducing leaks in the negative air plenum of the HVAC, maximum 2% total`,
        `Cab pressure, whether high or low, is NOT an indication of safe operator protection levels.  It can be correlated to positive flow rate and measure with a differential pressure gage.`,
      ],
    },
    sectionFilterFitTest: {
      //   h2: "Filter Fit Test",
      a0: `Fit Factor and Protection Factor `,
      a1: `Cab Protection Factor Template`,
      a2: "ASABE X613 Cabin Filtration ",
    },
    sectionQualityTested: {
      h2: "Quality Tested",
      ul: [
        "Every individual component used in manufacturing is tested to the highest degree to identify the very best product and supplier.",
        "The completed product is tested to verify performance to design criteria and validate manufacturing processes and procedures.",
        "Ongoing quality control ensures all components / products meet established Clean Air Filter standards.",
        "Collection of data establishes baseline",
      ],
      a1: `Cab Protection Factor Template`,
      a2: `ASABE X613 Cabin Filtration`,
    },
    sectionPerformanceProven: {
      h2: "Performance Proven",
      ul: [
        "Infield / onsite testing is utilized to establish actual “Performance”.",
        "Data is digitally collected and sent to Clean Air Filter for evaluation and reports.",
        "Actual equipment inspection is important to identify leaks and issues need to optimize performance.",
        "Actual performance is correlated to lab performance baseline.",
      ],
    },
    section2: {
      h2: "Failure of Gravimetric Testing",
      p1:
        "Gravimetric testing is not sensitive to the 0.3µm particle size. Therefore the expert team at CAF utilizes the particle counting methodology in cab and filter testing",
      a: "The Failure of Gravimetric Testing ",
    },
    section3: {
      h2: "MERV 16",
      p1: `The Minimum Efficiency Reporting Value (MERV) is a label that the heating & air conditioning industry (ASHRAE) created. The ASHRAE MERV Standard is a gravimetrically based efficiency testing, correlating filters with HVAC flow rates. MERV 16 is ASHRAE's highest filter rating. There used to be higher ratings claiming (MERV 17-20) HEPA, but these ratings were removed a couple of years ago as it conflicted with HEPA standards.`,
      p2: `The Current MERV 16 testing allows a new filter, at the start of their testing, to have a minimum efficiency of only 80% at 0.3µm. After adding a specific amount of dust, the filter as to be at an efficiency of at least 95% at 0.3 to 1.0µm in order to classify as MERV 16.`,
      p3: `This rating makes sense for HVAC because ASHRAE is in the industry of heating and air conditioning. In order to make their HVAC systems work in homes, etc., they needed high airflow across the coils (The higher the air flows, the more comfortable it feels).`,
      p4: `When you put a filter in line with an HVAC unit, it slows the air down dramatically. As the filter collects dust, it gets more restrictive until the airflow does not allow the HVAC unit to keep you comfortable. So, users need filters with great airflow, which results in low-efficiency filters. On mechanical filters, the higher the efficiencies, the greater the restriction.`,
      p5: `So how does one make a standard look good? Rate the filter at its dust-loaded efficiency and not its off-the-self efficiency and use that as the resting. Where HEPA filters are rated is based on the initial fractional efficiency.`,
      p6: `Considering that MERV 16 starts at 80% at 0.3µm, how much will a person inhale by the time it dust loads to that minimum of 95% at 0.3 to 1.0µm? Field test data shows that you would get exposed to as many particles on the first day using MERV 16 as you would in over six months with a CAF 48R filter.`,
      p7: `The range of MERV 16 is 80% at 0.3µm to 99.969% at 0.3 to 1.0µm. This basically makes it ineffective as a filter standard for high-efficiency filters. All CAF measurements are taken at 0.3µm.`,
      a: "MERV 16-ASABE Response ",
    },
    section4: {
      h2: "Filter Fit Test",
      p1:
        "CAF experts address the “Fit Test.” The critical point being overlooked is the correlation of a 0.3 µm particulate fit test for vapor cartridge usage.",
      a: "Fit Factor for ASABE X613 Cabin Filtration ",
    },
  },
}
