export default [
  {
    to: "/products",
    url: "/products/filtration-protection-systems",
    label: "Products",
    children: [
      {
        to: "/filtration-protection-systems",
        label: "Filtration Protection Systems",
        children: [
          { to: "/fps55", label: "FPS 55 Series" },
          { to: "/fps955", label: "FPS 955 Series" },
          { to: "/fps-filters", label: "FPS Filters" },
        ],
      },
      {
        to: "/cabin-air-filters",
        label: "Cabin Air Filters",
        children: [
          { to: "/limitations", label: "Limitations" },
          { to: "/maintenance", label: "Maintenance" },
          { to: "/warranty", label: "Warranty" },
        ],
      },
      {
        to: "/air-testing-services",
        label: "Air Testing Services",
      },
    ],
  },
  {
    to: "/caf-standard",
    label: "CAF Standard",
    width: 120,
    children: [
      {
        to: "/quality-tested",
        label: "Quality Tested",
      },
      {
        to: "/performance-proven",
        label: "Performance Proven",
      },
    ],
  },
  { to: "/rd", label: "R&D" },
  { to: "/resources", label: "Resources" },
  {
    to: "/contact",
    label: "Contact",
    children: [
      {
        to: "/need-technical-assistance",
        label: "Need Technical Assistance?",
      },
      {
        to: "/submit-your-testimonial",
        label: "Submit Your Testimonial",
      },
    ],
  },
  {
    to: "/about",
    label: "About",
    children: [
      {
        to: "/testimonials",
        label: "Testimonials - English",
        partiallyActive: false,
      },
      {
        to: "/french-testimonials",
        label: "Testimonials - French",
        partiallyActive: false,
      },
    ],
  },
]