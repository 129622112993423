import React from 'react';
import searchStyle from './search.module.scss';
import { ReactComponent as SearchIcon } from '../../styles/icons/search-regular.svg';
import { ReactComponent as ExitIcon } from '../../styles/icons/times-circle-solid.svg';
import data from '../../data';
import { Link } from 'gatsby';
export default function (props) {
    const [key, setKey] = React.useState("");
    const [timer, setTimer] = React.useState(null);
    const [results, setResults] = React.useState([]);
    const [finding, setFinding] = React.useState(false);
    const search = () => {
        console.log(key);
        if (key) {
            for (const item in data) {
                // console.log(JSON.stringify(data[item].content).includes(key));
                if (JSON.stringify(data[item].content).toLowerCase().includes(key.toLowerCase())) {
                    results.push(data[item]);
                    setResults(results);
                }
            }
            setFinding(false);
        }
    };

    return <form className={searchStyle.wrapper}>
        <input
            value={key}
            onChange={(e) => {
                setFinding(true);
                setKey(e.currentTarget.value);
            }}
            onKeyUp={e => {
                setKey(e.currentTarget.value);
                setResults([]);
                clearTimeout(timer);
                setTimer(setTimeout(search, 1000));
            }}
        ></input>
        <span>{key ? <ExitIcon
            onClick={() => { setKey(""); }}
        ></ExitIcon> : <SearchIcon></SearchIcon>}</span>
        <div className={searchStyle.results}>
            {key && finding ? <p>searching...</p> : null}
            {key && !finding && results.length ?
                <div className={searchStyle.result}>
                    <p>{`Pages contain "${key}":`}</p>
                    {results.map(item => {
                        return <p key={item.slug}>
                            <Link to={item.url}>{item.title}</Link>
                        </p>;
                    })
                    }</div> : null}
            {key && !finding && !results.length ? <p>{`No pages contain "${key}"`}</p> : null}
        </div>
    </form>;
}