export default {
  slug: "maintenance",
  url: "/products/cabin-air-filters/maintenance",
  title: "Maintenance",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "Filter Service Life.",
      },
      p1: `<strong>Particulate / Vapor Filter</strong>`,
      p2: `Vapor Filter:`,
      ol: {
        li1: `Directly monitor the vapors using real-time methodologies (recommended).`,
        li2: `Measure the concentration of all vapors and calculate service life (may lead to incorrect filter life calculations).`,
        li3: [
          `When the first two approaches are not possible, replace the filter when one of the following three conditions occurs:`,
          [
            `Taste, odor, or symptom of a contaminant is detected within the enclosure.`,
            `12 months of use`,
            `500 hours of use`,
          ],
        ],
      },
    },
    section2: {
      p0: `Particulate Filter:`,
      ul: [
        `Replace the primary filter when the flow rate is less than 18 CFM for one occupant or 22 CFM for two occupants.`,
        `Flow rates are measured with a hot wire (CAF-recommended test procedure) and correlate to a differential pressure gauge to indicate minimum flow.`,
        `When measuring with a hot wire instrument is not possible:`,
        [
          `Install a differential pressure gauge`,
          `Install a new filter`,
          `Run the system`,
          `Mark the differential pressure gauge with a magic marker`,
          `Replace filter when the reading of the pressure gauge is ½ of the original reading`,
        ],
        `<em>CAUTION:</em> The paper media can be cleaned using a shop vac on the dirty side. Do not wash or clean with water. The activated carbon section of the filter cannot be cleaned.`,
      ],
    },
    section3: {
      p0: `Recirculation Filter:`,
      ul: [
        `If applicable, contact the filter supplier for maintenance procedures.`,
      ],
    },
    section4: {
      p0: `Disposal of filter:`,
      //   ul: [
      //     `If applicable, adhere to all State, Federal, and International guidelines pertaining to the contaminant being filtered.`,
      //   ],
      ul: [
        `Adhere to all State, Federal, and International guidelines pertaining to the contaminant being filtered.`,
      ],
    },
  },
}
