export default {
  slug: "limitations",
  url: "/products/cabin-air-filters/limitations",
  title: "Limitations",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "Limitations.",
      },
      p1: `Improper installation will affect system performance. Inadequate contact of sealing gasket due to loose fit or obstruction will cause bypass leakage. The filter is designed to augment respirator devices; use an approved respirator when law dictates. CAF filters will not provide oxygen in an oxygen-deficient atmosphere. Not for use as protection against fumigants. A high-oil environment may decrease the filter efficiency.`,
      p2: `The protection level will vary with individual cab integrity. Positive airflow, greater than 18 CFM, must be maintained to provide adequate air supply for a single occupant.  Two occupants require greater than 22 CFM.`,
      p3: `CAUTION: Cab pressure, whether high or low, is NOT an indication of safe operator protection levels.`,
      a1: `Cab Pressure is Not An Indication of Cab Protection`,
    },
  },
}
