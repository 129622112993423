import React from 'react';
import Img from "gatsby-image";
import mainHeaderStyle from "./mainHeader.module.scss";
import { Link, useStaticQuery, graphql } from 'gatsby';
export default function (props) {

    const { waves, wavesM } = useStaticQuery(
        graphql`query headers {
            waves: allFile(filter: {sourceInstanceName: {eq: "waves"}}) {
                edges {
                    node {
                        relativePath
                        childImageSharp {
                            fluid(maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
            wavesM: allFile(filter: {sourceInstanceName: {eq: "wavesM"}}) {
                edges {
                    node {
                        relativePath
                        childImageSharp {
                            fluid(maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        }`
    );


    const secondaryHeaderWaves = waves.edges.map(wave => {
        const results = [wave.node.childImageSharp.fluid];
        let waveMobile = wavesM.edges.find(item => item.node.relativePath === wave.node.relativePath);

        if (waveMobile) {
            waveMobile.node.childImageSharp.fluid.media = `(max-width: 700px)`;
        } else {
            waveMobile = null;
        }
        
        results.push(waveMobile ? waveMobile.node.childImageSharp.fluid : null);
        return results;
    });


    const imgHeader = secondaryHeaderWaves.find(image => image[0].src.includes(props.currentPageProps.to.slice(1))) ||
        secondaryHeaderWaves.find(image => image[0].src.includes('pressurizers'));
    
        console.log(imgHeader)
    return <header className={mainHeaderStyle.wrapper}>
        {imgHeader ? <Img className={mainHeaderStyle.image} fluid={
            [
                {
                    ...imgHeader[0],
                    key: 1,
                    media: `(min-width: 700px)`,
                },
                {
                    ...imgHeader[1],
                    key: 2,
                    media: `(max-width: 699px)`,
                }
            ]}></Img> : null}
        <h3 className={mainHeaderStyle.title}>{props.currentPageProps.label}</h3>
    </header>;
}