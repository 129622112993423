export default {
  slug: "resources",
  url: "/resources",
  title: "Resources",
  content: {
    section1: {
      h1: {
        head: "Learn more about",
        tail: "Clean Air Filter, Co.",
      },
      p1:
        "At Clean Air Filter, we are committed to going beyond the standard. Not only in our products and testing but in providing our customers resources so they can learn about how our products protect them every day.",
    },
    section2: {
      h2: "Downloads",
      a1: "Application Guide",
      a15: "FPS Basic Installations",
      // a16: "FPS Basic Installations (pptx)",
      a2: "Cab Protection Factor Template",
      a3: "CAF48R Certificate",
      a4: "Filter Service Life",
      a5: "FPS Installation Manual",
      a6: "Particulate System Filter Test Procedure",
      a7: `Vapor Test Comparisons`,
      a8: `Recommended Flow Rate`,
      a9: `Fit Factor`,
      a10: `The Failure of Gravimetric Testing`,
      a11: `Merv 16 is Not Applicable to Cabin Filtration`,
      a12: `Merv 16 ASABE Response`,
      a13: `Fit Factor and Protection Factor `,
      a13: `Fit Factor and Protection Factor `,
      a14: `Abbreviations and Acronyms`,
    },
  },
}
