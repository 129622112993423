export default {
  slug: "fps-filters",
  url: "/products/filtration-protection-systems/fps-filters",
  title: "FPS Filters",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "FPS Filters.",
      },
      p1: `Clean Air Filter® provides vapor and particulate filtration, featuring a unique, patented 3-in-1 design for our Filtration Protection Systems.`,
    },
    section2: {
      h2: `CAF47R`,
      p1: `The CAF47R filter, utilized in the FPS55 Series, is a multi-stage particulate/vapor filter.`,
      ol: `<ol>
                <li><p>The first stage is a high capacity dust filter composed of cellulose. For decades this paper media has been used in agriculture for the large dust particles > 10µm. It not only removes the large dust particles it also absorbs liquid aerosol droplets.</p></li>
                <li><p>This filter’s second stage is a super high respirator grade activated carbon (proprietary to CAF) specially processed only at CAF. This vapor adsorbing media is layered contiguously between the pleats of the first stage paper media. The airflow to this media is controlling the dust loading of the first stage.</p></li>
                <li><p>The third stage is a respirator-grade submicron FINAL FILTER.</p></li>
            </ol>`,
      p2: `The CAF47R has excellent organic vapor adsorption capabilities along with maximum airflows. The test results show a filter efficiency of 99.98 @ 0.3µm, test report 081113A.`,
    },
    section3: {
      h2: `CAF48R`,
      p1: `The CAF48R, utilized in the FPS55 Series, meets the EN15695, category 3 for particulates and category 4 for vapors, in qualifying cabs.`,
      ol: `<ol>
                <li><p>The first stage is a high capacity dust filter composed of cellulose. For decades this paper media has been used in agriculture for the large dust particles > 10µm. It not only removes the large dust particles it also absorbs liquid aerosol droplets.</p></li>
                <li><p>The second stage of this filter is a super high respirator grade activated carbon (proprietary to CAF) specially processed only at CAF. This vapor adsorbing media is layered contiguously between the pleats of the first stage paper media. The airflow to this media controls the dust loading of the first stage and serves as the vapor adsorbent.</p></li>
                <li><p>The third stage is a respirator grade submicron FINAL FILTER.</p></li>
            </ol>`,
      p2: `The CAF48R test results show a filter particulate efficiency of >99.95 mpps. Additional test results show a filter vapor efficiency of >70 minutes, exceeding Standards EN 15695-4 and ASABE S-613.`,
      a1: `Filter Service Life`,
      a2: `Filter Service Life`,
    },
    section4: {
      h2: `CAF950R`,
      p1: `The CAF950R filter, utilized in the FPS955 Series, is a multi-stage particulate/vapor filter`,
      ol: `<ol>
                <li><p>The first stage is a high capacity dust filter composed of cellulose. For decades this paper media has been used in agriculture for the large dust particles > 10µm. It not only removes the large dust particles it also absorbs liquid aerosol droplets.</p></li>
                <li><p>The second stage of this filter is a super high respirator grade activated carbon (proprietary to CAF) specially processed only at CAF. This vapor adsorbing media is layered contiguously between the pleats of the first stage paper media. The airflow to this media is controlling the dust loading of the first stage and serves as the vapor adsorbent.</p></li>
                <li><p>The third stage is a respirator grade submicron FINAL FILTER.</p></li>
            </ol>`,
      p2: `The CAF950R is a larger version of the CAF48R. Test results show a filter efficiency of >99.99 @ 0.3µm, test report 181008-1.`,
      a: `Filter Service Life`,
    },
    section5: {
      h2: `FPS Filter Comparison`,
      table: `<table class='airflow-comparison'>

                <tbody>
                    <tr>
                        <th>FPS Filter Type</th>
                        <th>FPS System</th>
                        <th>Power Supply</th>
                        <th>Air Flow</th>
                        <th>Efficiency @ mpps</th>
                        <th>Efficiency @ 0.3µm</th>
                        <th>Standard</th>
                    </tr>
                    <tr>
                        <td>CAF48R</td>
                        <td>FPS 55.2 w/pre-cleaner</td>
                        <td>24 Volt DC</td>
                        <td>±48.09</td>
                        <td>> 99.95 mpps	</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>EN15695:\n2,3,&4</td>
                    </tr>
                    <tr>
                        <td>CAF47R</td>
                        <td>FPS 55.2 w/pre-cleaner</td>
                        <td>24 Volt DC</td>
                        <td>±54.72</td>
                        <td>-</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>CAF48R</td>
                        <td>FPS 55.2 w/pre-cleaner</td>
                        <td>12 volt DC</td>
                        <td>±45.50</td>
                        <td>> 99.95 mpps	</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>EN15695:\n2,3,&4</td>
                    </tr>
                    <tr>
                        <td>CAF47R</td>
                        <td>FPS 55.2 w/pre-cleaner</td>
                        <td>12 volt DC</td>
                        <td>±49.52</td>
                        <td>-</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>CAF48R</td>
                        <td>FPS 55.1 w/pre-cleaner</td>
                        <td>115 volt AC</td>
                        <td>±34.88</td>
                        <td>> 99.95 mpps	</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>EN15695:\n2,3,&4</td>
                    </tr>
                    <tr>
                        <td>CAF47R</td>
                        <td>FPS 55.1 w/pre-cleaner</td>
                        <td>115 volt AC</td>
                        <td>±49.72</td>
                        <td>-</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>CAF950R</td>
                        <td>FPS955.2 w/o pre-cleaner</td>
                        <td>-</td>
                        <td>±154.7</td>
                        <td>> 99.95 mpps	</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>EN15695:\n2,3,&4</td>
                    </tr>
                    <tr>
                        <td>CAF950R</td>
                        <td>FPS955 w/o pre-cleaner</td>
                        <td>220 volt DC</td>
                        <td>±151.6</td>
                        <td>> 99.95 mpps	</td>
                        <td>> 99.99 @ 0.3 µm</td>
                        <td>EN15695:\n2,3,&4</td>
                    </tr>
                </tbody>
            </table>`,
    },
  },
}
