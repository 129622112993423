export default {
  slug: "filtration-protection-systems",
  url: "/products/filtration-protection-systems",
  title: "Filtration Protection Systems",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "FPS.",
      },
      p1: `Clean Air Filter® has utilized the latest technology and research to develop the ultimate product in air filtration to create a cleaner, healthier environment.`,
      p2: `Our Filtration Protection Systems (FPS) are designed to provide clean, filtered air to your environment. Air exiting the FPS unit creates positive airflow inside a properly sealed enclosure. This over-pressure pushes clean air out through leaks in the enclosed side of the system, rather than allowing contaminated air to enter.`,
    },
    section2: {
      h2: `FPS 55 Series`,
      p: `Compact add-on unit for mobile equipment.`,
      a: `View FPS 55 Series`,
    },
    section3: {
      h2: `FPS 955 Series`,
      p: `For offices, control rooms, and residential applications.`,
      a: `View FPS 955 Series`,
    },
  },
}
