export default {
  slug: "testimonials",
  url: "/about/testimonials/",
  title: "Testimonials",
  content: {
    section1: {
      h1: {
        head: "See why",
        tail: "Our Customers Love Us.",
      },
      p1:
        "You place your trust in our products to protect you from harm. That is a responsibility we do not take lightly. Through a constant cycle of rigorous, methodical, and painstaking testing and evaluation, we reinforce our dedication to quality because that’s what keeps our customers safe, healthy, and satisfied year after year.",
    },
    section2: {
      h2: `DDPS SAS - France (In English)`,
      quotes: [
        {
          p: [
            `Specialized in the comfort and safety of machine cabin users, I have tested multiple models of pressurization and filtration systems.`,
            `Why did I choose the Clean Air Filter FPS? The FPS corresponds to and exceeds the EN15695 standard (filtration of microparticles and filtration of gases). The performance of the FPS places it in the very high end of filtration and pressurization devices. The FPS performance has also been verified in France with several users and under different site conditions (construction companies, recycling centers, ports, military vehicles).`,
            `The ease of installation of the FPS and its versatility has allowed my company to distribute this device nationally and internationally. In 90% of sales, the end-user installed the FPS themselves on their machine(s). The technical assistance of Clean Air Filter has enabled my company to provide all the documents required by the various official European bodies for monitoring the protection of machine users.`,
            `The attractive selling price of the FPS and its very high performance has allowed us to establish ourselves profoundly in many markets (construction or handling machines, port facilities, military design offices, etc.).`,
            `So, within two years, the distribution of FPS has become of the utmost importance in the activity of our company (DDPA SAS specialized in the activities of air conditioning and spare parts) because our customers have become our best sales agents. I am thinking of all those who started by buying a single device and who, two months later, did not hesitate to equip a large part of their equipment fleet.`,
          ],
          author: `Jean CANDAU-TILH <br> Former CEO of DDPA SAS - France`,
        },
        {
          p: [
            `For five to six years, my company [DDPA] and Clean Air Filter have collaborated to sell filtration pressurization systems in France.`,
            `We chose to work with these systems because they have one of the best filtration capabilities and results. In addition, the systems are so easy to install that our customers install it themselves. The fact that they are compact, and there is only one filter in the system makes it convenient and easy to use.`,
            `The advantage of working with Clean Air Filter is also the fact that they have daily technical assistance. As proof, their teams do not hesitate to come to France to train us and more, visit our customers with us to run tests on the installations. This is very valuable for our customers and for us. This reinforces the seriousness and professionalism of Clean Air Filter.`,
            `To increase our development in the European and French markets, Clean Air Filter did not hesitate to obtain the CE standard for pressurization systems. This is very important for our market, with its constantly updating standards.`,
            `Now, Clean Air Filter allows us to have buffer stock in our warehouse. This enables us to react quickly, in terms of delivery.`,
            `The choice of Clean Air Filter was easy and obvious for me. I am sure that the CAF/DDPA collaboration has a bright future ahead of us.`,
          ],
          author: `Sébastien BILHOU <br> Director, DDPA SAS - France`,
        },
      ],
    },
    section3: {
      h2: `DDPS SAS - France (En français)`,
      quotes: [
        {
          p: [
            `Spécialisé dans le confort et la sécurité des utilisateurs de cabines d’engins, j’ai testé de multiples modèles de systèmes de pressurisation et filtration.`,
            `Pourquoi je choisi le FPS de Clean Air Filter? Le FPS correspond et surpasse la norme EN15695: filtration des microparticules et filtration des gaz. Les performances du FPS le placent dans le très haut de gamme des appareils de filtration et pressurisation. Les performances du FPS ont été vérifiées en France auprès de plusieurs utilisateurs et en conditions de chantier (entreprises de construction,déchetteries, ports, engins militaires).`,
            `La facilité d’installation du FPS ainsi que sa polyvalence ont permis à mon entreprise de distribuer cet appareil au niveau national et international. Dans 90% des ventes, l’utilisateur final installe lui-même le FPS sur sa ou ses machines. L’aide technique de Clean Air Filter a permis à mon entreprise de fournir tous les documents exigés par les différents organismes officiels Européens de contrôle de la protection des utilisateurs de machines.`,
            `Le prix de vente attractif du FPS et ses performances très élevées nous ont permis d’accéder et de nous implanter profondément dans de nombreux marchés (Machines de chantier ou de manutention, installations portuaires, bureaux d’études militaires, etc.).`,
            `Ainsi, en l’espace de deux ans, la distribution des FPS a pris une importance capitale dans l’activité de l’entreprise (DDPA SAS spécialisée dans les activités d’air conditionné et pièces détachées) car nos clients sont devenus nos meilleurs agents commerciaux. Je pense à tous ceux qui ont commencé par l’achat d’un seul appareil et qui, deux mois plus tard, n’hésitaient pas à équiper une grand partie de leur parc de matériels.`,
            `En conclusion, je ne m’accorde qu’un seul mérite: celui d’avoir sélectionné le meilleur appareil de Filtration Pressurisation disponible sur le marché.`,
          ],
          author: `Jean CANDAU-TILH <br> Former CEO of DDPA SAS - France`,
        },
        {
          p: [
            `Cela va faire 5-6 ans que ma société DDPA et Clean Air Filter collabore pour la vente de systèmes de filtration pressurisation.`,
            `Nous avons choisi de travailler avec ces systèmes car ils ont l’une des meilleures capacités de filtration et de résultats. De plus les systèmes sont tellement faciles à installer que nos clients les installent par eux-mêmes. Le fait qu’ils soient compacts, qu’il n’y a qu’un seul filtre dans le système, le rend pratique et facile à utiliser.`,
            `L’avantage de travailler avec Clean Air Filter, est aussi le fait d’avoir une aide technique au quotidien. Pour preuve leurs équipes n’hésitent pas à venir en France pour visiter avec nous nos clients et ainsi faire des tests sur les installations. Ceci est très appréciable pour nos clients et pour nous. Ceci renforce le sérieux et le professionnalisme de Clean Air Filter.`,
            `Pour accroître notre développement sur le marché Européen et Français, Clean Air Filter n’a pas hésité à faire obtenir la norme CE pour les systèmes de pressurisation. Ceci est très important pour notre marché avec des normes sans cesse remise à jours.`,
            `Aujourd’hui, Clean Air Filter nous permet d’avoir un stock tampon dans nos locaux. Ceci nous permet une réactivité rapide en termes de livraison.`,
            `Ce choix de Clean Air Filter me paraît comme une évidence. Je suis sûr que la collaboration CAF/DDPA à de beaux jours devant nous`,
          ],
          author: `Sébastien BILHOU <br> Directeur, DDPA SAS - France`,
        },
      ],
    },
  },
}
