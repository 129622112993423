export default {
    content: `<section>
        <p><span class="bolded">CAUTION:</span> <em>The enclosure MUST have positive airflow, greater than 18 Cubic Feet per Minute (CFM).</em></p>

       
    </section>`,
    files: ["FPS Installation Instruction Manual", "FPS PARTICULATE/VAPOR FILTERS", "Vapor Test Procedure"]
};


{/* <p><em>Standard: EN15695 requires a minimum cab pressure of 20PA (0.08”) of water</em></p> */}