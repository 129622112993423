import React from "react"
import { Link as GatsbyLink } from "gatsby"
import navItemStyles from "./navItem.module.scss"
import navLinkStyles from "../styles/navLink.module.scss"
import DropDownMenu from "../dropdown"

export default function NavItem(props) {
  // console.log(props)
  return (
    <div
      // key={props.index}
      className={
        props.mobileOnly
          ? navItemStyles.wrapperMobileOnly
          : navItemStyles.wrapper
      }
    >
      <span style={{ visibility: "hidden" }}>
        {props.children ? props.label : null}
      </span>
      {props.children ? (
        DropDownMenu({
          index: props.index,
          setExpandingItem: props.setExpandingItem,
          expanded: props.expanded,
          label: props.label,
          children: props.children,
          to: props.to,
          width: props.width || null,
          partiallyActive: props.partiallyActive || true,
        })
      ) : (
        <GatsbyLink
          to={props.to}
          className={navLinkStyles.wrapper}
          activeClassName={navLinkStyles.active}
          width={props.width || null}
          partiallyActive={props.partiallyActive || true}
        >
          {props.label}
        </GatsbyLink>
      )}
    </div>
  )
}
