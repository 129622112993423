import React, { useState } from "react"
import { Link } from "gatsby"
import dropdownStyles from "./dropdown.module.scss"
import { ReactComponent as Arrow } from "../../styles/icons/arrow-red.svg"

export default function DropDownMenu(props) {
  const [grandChildrenOpen, setGranchildrenOpen] = useState(false)

  return (
    <li
    // key={props.child.to}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-end",
          alignItems: "center",
          minWidth: "max-content",
        }}
      >
        <Link
          activeClassName={dropdownStyles.active}
          to={props.to + props.child.to}
          key={props.child.label}
          partiallyActive={props.partiallyActive}
        >
          {props.child.label}
        </Link>
        {props.child.children ? (
          <Arrow
            style={{
              transform: props.expanded ? "rotate(90deg)" : "rotate(0deg)",
              transition: "0.1s",
            }}
            onClick={() => {
              props.setExpanded(props.expanded ? null : props.index)
            }}
          ></Arrow>
        ) : (
          <Arrow style={{ visibility: "hidden" }}></Arrow>
        )}
      </div>
      {props.child.children ? (
        <ul
          className={props.expanded ? null : dropdownStyles.grandChildrenClosed}
        >
          {props.child.children.map(properties => (
            <li key={properties.to}>
              <Link
                partiallyActive={props.partiallyActive}
                to={props.to + props.child.to + properties.to}
              >
                {properties.label}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  )
}
