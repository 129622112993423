import React from 'react';
// import mainStyle from './main.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import Breadcrumb from '../breadcrumb';
import MainHeader from '../mainHeader';
import SideCallOuts from '../sideCallOut';
import Content from '../layoutContent';
import navItems from '../../data/navItems'

export default function (props) {
    const data = useStaticQuery(graphql`
            query headerImages {
                secondaryWave: file(relativePath: {eq: "SecondaryWave.png"}) {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                testing: file(relativePath: {eq: "Testing-Approval.png"}) {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                        fixed(width: 450) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
                testingM: file(relativePath: {eq: "Testing-Approval.png"}) {
                    childImageSharp {
                        fixed(width: 200) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }`);


    return (
      <main>
        <MainHeader currentPageProps={props.currentPageProps}></MainHeader>
        <div
          className="main-body"
          style={
            props.currentPageProps.to === navItems[1]
              ? { maxWidth: 1800 }
              : null
          }
        >
          <aside className="main-sidebar">
            <Breadcrumb breadcrumb={props.breadcrumb}></Breadcrumb>
            <SideCallOuts></SideCallOuts>
          </aside>
          <Content children={props.children}></Content>
          {props.currentPageProps.to === navItems[1] ? (
            <span className="float-right  testing-approval-img">
              <Img
                // className="testing-approval-img"
                fixed={[
                  {
                    ...data.testing.childImageSharp.fixed,
                    key: 1,
                    media: `(min-width: 1200px)`,
                  },
                  {
                    ...data.testingM.childImageSharp.fixed,
                    key: 2,
                    media: `(max-width: 1199px)`,
                  },
                ]}
              ></Img>
            </span>
          ) : null}
        </div>
        <Img fluid={data.secondaryWave.childImageSharp.fluid}></Img>
      </main>
    )
}