export default {
  slug: "warranty",
  url: "/products/cabin-air-filters/warranty",
  title: "Warranty",
  content: {
    section1: {
      h1: {
        head: "",
        tail: "Warranty.",
      },
      p1: `It is impossible to eliminate all risks inherently associated with the use of this product. Ineffectiveness or other unintended consequences may result because of such factors as improper installation, HVAC leakage, presence of other materials, or the manner of use or application, all of which are beyond the control of CAF, the manufacturer, or the seller. In no case shall CAF, the manufacturer, or seller be liable for consequential, special or indirect damages resulting from the use or handling of this product. The buyer/operator/end user shall assume all such risks.`,
      p2: `Except as expressly provided herein, CAF, the manufacturer, or the seller makes no warranties, guarantees, or representations of any kind, either express or implied or by the usage of trade, statutory or otherwise with regard to the product sold, including but not limited to, merchantability, fitness for particular trade usage. Buyers or users exclusive remedy, and CAF, the manufacturer, or sellers total liability, shall be for damages not exceeding the cost of the product.`,
      p3: `Clean Air Filter Limited 90 Day Warranty`,
      p4: `The Clean Air Filter Company warrants its products to be free from defects in material and workmanship. If one of our products fails because of a manufacturing or workmanship defect, Clean Air Filter will replace the product subject to the following conditions:`,
      p5: `<em>Warranty Period:</em> The air filtration product is warranted to be free of defects in material and workmanship for 90 days from the date the product is purchased.`,
      p6: `<em>What is covered:</em> This limited warranty covers air filtration products manufactured and sold by Clean Air Filter.`,
      p7: `<em>What is not covered:</em> This limited warranty DOES NOT COVER`,
      ul: [
        `Failures resulting from incorrect application`,
        `Damage caused by improper installation, improper or abnormal use, misuse, neglect or accident or unauthorized repair.`,
        `Failures resulting from parts not sold or approved by Clean Air Filter.`,
        `Damage to parts, fixtures, housings, attachments, and accessory items that are not part of the air filter, including check valves and hoses.`,
        `Damages as a result of flood, wind, fire, lightning, accidents, corrosive environment, an act of God, or any other condition beyond the control of Clean Air Filter.`,
        `Incidental, consequential, commercial, punitive, exemplary, or special damages or losses.`,
        `Filters that have been cleaned, reinstalled, or both.`,
      ],
      p8: `There are no warranties which extend beyond the description on the face hereof. This limited warranty is VOID if the product has been altered or modified in any way, including attempted repair without Clean Air Filter’s authorization.`,
      p9: `Clean Air Filter’s responsibilities: If a defect in material or workmanship not caused by an excluded failure is found during the warranty period, Clean Air Filter will provide, at Clean Air Filter’s choice, new parts or Clean Air Filter approved repair parts or assembled components needed to correct the defect. Clean Air Filter reserves the right to provide a refund in lieu of replacement or repair at Clean Air Filter’s option. The replacement or repaired product will be sent to you freight prepaid or made available to you for pick up at a local dealer designated by Clean Air Filter. Items replaced under this warranty become the property of Clean Air Filter and shall be returned to Clean Air Filter upon request.`,
      p10: `User’s responsibilities: Report your issue to Clean Air Filter by telephone 712.748.3642 or by fax to 712.748.3643 immediately. After contacting Clean Air Filter, you may be required to deliver or send the product to Clean Air Filter properly packaged, freight prepaid. The user is responsible for the cost of shipping and packing.`,
      p11: `Exclusions and Limitations on Damages and Remedies are as follows:`,
      p12: `THIS WARRANTY IS GIVEN IN LIEU OF ALL OTHER WARRANTIES, WRITTEN OR ORAL, WHETHER EXPRESSED BY AFFIRMATION, PROMISE, DESCRIPTION, DRAWING, MODEL OR SAMPLE. ANY AND ALL WARRANTIES, OTHER THAN THIS ONE, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, ARE HEREBY DISCLAIMED.`,
      p13: `Clean Air Filter IS NOT RESPONSIBLE FOR INCIDENTAL, CONSEQUENTIAL OR COMMERCIAL DAMAGES. REMEDIES UNDER THIS WARRANTY ARE LIMITED TO THE PROVISIONS FOR REPLACEMENT OR REPAIR OF THE WARRANTED PRODUCT AS SPECIFIED HEREIN.`,
      // p14: `For additional information regarding our products, please visit us at <a class="anchor-link" href="www.cleanairfilter.com">www.cleanairfilter.com</a>`
    },
  },
}
