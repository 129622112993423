import React from "react";
import { StaticQuery, graphql, Link } from 'gatsby';
import { ReactComponent as Arrow } from '../../styles/icons/arrow.svg';
import breadcrumbStyle from './breadcrumb.module.scss';

export default function (props) {
    const reducer = (acc, curr, index) => {
        const link = <Link
            key={index} to={acc[index - 1] ?
                acc[index - 1].props.to + curr.to :
                curr.to}><Arrow></Arrow>{curr.label}
        </Link>;
        acc.push(link);
        return acc;
    };

    return <div className={breadcrumbStyle.wrapper}>
        <Link to='/'>Home</Link>
        {props.breadcrumb.reduce(reducer, [])}
    </div>;
}