import React from "react"
import navItemsStyles from "./navItems.module.scss"
import NavItem from "./navItem"
import SocialBar from "../socialBar"
import PhoneButton from "./phoneButton"
import { Link } from "gatsby"
import SearchBar from "../search"

export default function NavItems(props) {
  const [expandingItem, setExpandingItem] = React.useState(0)

  return (
    <div className={navItemsStyles.wrapper}>
      <div
        className={
          props.navClosed ? navItemsStyles.closed : navItemsStyles.open
        }
      >
        <div className={navItemsStyles.contact}>
          <SearchBar></SearchBar>
          <PhoneButton style={{ opacity: props.top ? 1 : 0 }}></PhoneButton>
        </div>

        <div className={navItemsStyles.nav}>
          <div className={navItemsStyles.menu}>
            {props.navItems.map((item, index) => (
              <NavItem
                {...item}
                setExpandingItem={setExpandingItem}
                index={index}
                expanded={expandingItem === index}
                key={index}
              ></NavItem>
            ))}
          </div>
          <PhoneButton style={{ opacity: props.top ? 0 : 1 }}></PhoneButton>
        </div>
      </div>
    </div>
  )
}
