import React, { useState } from "react";
import phoneStyle from './phoneButton.module.scss';
import { ReactComponent as PhoneIcon } from './icons/phone-alt-solid.svg';

export default function PhoneButton(props) {
    const [open, setOpen] = useState(false);
    return <div className={phoneStyle.wrapper}
        style={props.style}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
    >
        {<a className={open ? phoneStyle.open : phoneStyle.closed} href={"tel:712.748.3642"}>{open ? "712.748.3642" : ""}</a>}
        {"\xa0"}
        <button className={phoneStyle.button}>{<PhoneIcon></PhoneIcon>}</button>
    </div>;
}