export default {
  slug: "cabin-air-filters",
  url: "/products/cabin-air-filters",
  title: "Cabin Air Filters",
  content: {
    section1: {
      h1: {
        head: "World-class",
        tail: "Cabin Air Filters.",
      },
      p1:
        "Clean Air Filter® provides a filter to reduce both particulates and vapors within the same filter housing.  This is by design, Patent# 5,423,903.",
      ul: {
        li1:
          '<p style="width: 100%;"><span  class="stage-bullet">Stage 1</span> is a standard PAPER media designed for the heavy dust environments of agricultural, industrial, mining, and off-road conditions. Also, the paper is superior in absorbing liquid aerosol droplets that are present in the air.</p>',
        li2:
          '<p style="width: 100%;"><span  class="stage-bullet">Stage 2</span> is respirator-grade ACTIVATED CARBON. The porous nature of this media effectively adsorbs organic vapors, such as diesel and chemical fumes. CAF’s customized, refined activated carbon works on surface area, and our patented design maximizes this principle.</p>',
        li3:
          '<p style="width: 100%;"><span  class="stage-bullet">Stage 3</span> is a sub-micron FINAL FILTER that provides additional protection while allowing maximum airflow.</p>',
      },
      p2:
        "Our cabin air filters comply with the ISO 3795 burn rate standard, rated as self-extinguishing.",
    },
    floatRight: {
      p1: "Farmer tested and approved.",
      p2: "Fits in the existing cab filter housing.",
      p3:
        "Inverted pyramid air-flow helps to maximize carbons adsorption ability.",
      p4: "Reduces tunneling and channeling.",
    },
    additionSection: {
      h2: "Additional Products",
      p1: "Specialty Vapor Filters are available upon request/approval.",
    },
    links: [
      "Order Now 712.748.3642",
      "Application Guide",
      "View Limitations",
      "View Warranty",
      "View Maintenance",
    ],
    section2: {
      h2: "Benefits",
      p1:
        'The Clean Air Filter (CAF) is highly effective in reducing chemical spray (organic) vapors. CAF’s filter fits in the existing cab filter space. Its design does not diminish or reduce the original dust filter but rather expounds upon it making it a <q><span className="bolded">better overall dust filter</span></q>. This makes it an excellent choice for combines and windrowers as well as tractors, floaters and sprayers.',
    },
    section3: {
      h2: "Limitations",
      p1:
        "Improper installation may cause filter dysfunction. Inadequate contact of sealing gasket due to loose fit or obstruction may cause filter dysfunction. Filter is designed to augment respirator devices – Use approved respirator when law dictates. Filter will not provide oxygen in an oxygen deficient atmosphere. Not for use as protection against fumigants. High oil environment may decrease filter efficiency.",
      p2:
        "Protection level will vary with individual cab integrity. Positive cab pressure must be maintained! Positive pressure is not necessarily an indication of cab integrity.",
    },
    section4: {
      h2: "Maintenance",
      p1: "Filter life depends on several factors such as:",
      ul: {
        li1:
          '<p><span class="bolded">Exposure</span> (time, wind, humidity, filter location, fan speed, row crop or orchard application)</p>',
        li2:
          '<p><span class="bolded">Chemical</span> (chemical structure, volatility, concentration, etc.)</p>',
      },
      p2:
        '<span class="bolded">CAUTION: The paper media can be cleaned using a shop vac on the dirty side. Do not wash or clean with water. The activated carbon section of the filter cannot be cleaned. Use appropriate PPE when handling filters. Dispose of used filters in accordance with Federal, State, & Local laws/regulations.</span>',
    },
    section5: {
      h2: "Warranty",
      pWarranty1: `It is impossible to eliminate all risks inherently associated with the use of this
product. Ineffectiveness or other unintended consequences may result because
of such factors as improper installation, HVAC leakage, presence of other
materials, or the manner of use or application, all of which are beyond the control
of CAF, the manufacturer, or the seller. In no case shall CAF, the manufacturer,
or seller be liable for consequential, special or indirect damages resulting from
the use or handling of this product. All such risks shall be assumed by the buyer.`,
      pWarranty2: `Except as expressly provided herein, CAF, the manufacturer, or the seller makes
no warranties, guarantees, or representations of any kind, either express or
implied, or by usage of trade, statutory or otherwise with regard to the product
sold, including but not limited to, merchantability, fitness for a particular trade
usage. Buyers or users exclusive remedy, and CAF, the manufacturer, or sellers
total liability, shall be for damages not exceeding the cost of the product.`,
      p1:
        '<span class="bolded">Clean Air Filter Limited 90 Day Warranty</span>',
      p2:
        "The Clean Air Filter Company warrants its products to be free from defects in material and workmanship. If one of our products fails because of a manufacturing or workmanship defect, Clean Air Filter will replace the product subject to the following conditions:",
      p3:
        '<span class="bolded">Warranty Period:</span> The air filter is warranted to be free of defects in material and workmanship for 90 days from the date the product is purchased.',
      p4:
        '<span class="bolded">What is covered:</span> This limited warranty covers air filtration products manufactured and sold by Clean Air Filter.',
      p5:
        '<span class="bolded">What is not covered:</span> This limited warranty DOES NOT COVER',
      ul: {
        li1: "<p>Failures resulting from incorrect application</p>",
        li2:
          "<p>Damage caused by improper installation, improper or abnormal use, misuse, neglect or accident or unauthorized repair</p>",
        li3:
          "<p>Failures resulting from parts not sold or approved by Clean Air Filter</p>",
        li4:
          "<p>Damage to parts, fixtures, housings, attachments and accessory items that are not part of the air filter, including check valves and hoses</p>",
        li5:
          "<p>Damages as a result of flood, wind, fire, lightning, accidents, corrosive environment, act of God, or any other condition beyond the control of Clean Air Filter</p>",
        li6:
          "<p>Incidental, consequential, commercial, punitive exemplary, or special damages or losses</p>",
        li7: "<p>Filters that have been cleaned, reinstalled, or both</p>",
      },
      p6:
        "There are no warranties which extend beyond the description of the face hereof. This limited warranty is VOID if the air filter has been altered or modified in any way including attempted repair without Clean Air Filters authorization.",
      p7:
        '<span class="bolded">Clean Air Filters responsibilities:</span> If a defect in material or workmanship not caused by an excluded failure is found during the warranty period, Clean Air Filter will provide, at Clean Air Filters choice, new parts or Clean Air Filter approved repair parts or assembled components needed to correct the defect. Clean Air Filter reserves the right to provide a refund in lieu of replacement or repair at Clean Air Filters option. The replacement or repaired product will be sent to you freight prepaid or made available to you for pick up at a local dealer designated by Clean Air Filter. Items replaced under this warranty become the property of Clean Air Filter and shall be returned to Clean Air Filter upon request.',
      p8:
        '<span class="bolded">Users responsibilities:</span> Report your issue to Clean Air Filter by telephone 1-712-748-3642 or by fax to 1-712-748-3643 immediately. After contacting Clean Air Filter you may be required to deliver or send the air filter to Clean Air Filter properly packaged, freight prepaid. The user is responsible for the cost of shipping and packing.',
      p9:
        '<span class="bolded">Exclusions and Limitations on Damages and Remedies are as follows:</span> THIS WARRANTY IS GIVEN IN LIEU OF ALL OTHER WARRANTIES, WRITTEN OR ORAL, WHETHER EXPRESSED BY AFFIRMATION, PROMISE, DESCRIPTION, DRAWING, MODEL OR SAMPLE. ANY AND ALL WARRANTIES OTHER THAN THIS ONE, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, ARE HEREBY DISCLAIMED. CLEAN AIR FILTER IS NOT RESPONSIBLE FOR INCIDENTAL, CONSEQUENTIAL OR COMMERCIAL DAMAGES. REMEDIES UNDER THIS WARRANTY ARE LIMITED TO THE PROVISIONS FOR REPLACEMENT OR REPAIR FO THE WARRANTED PRODUCT AS SPECIFIED HEREIN.',
      p10:
        "This warranty gives you specific legal rights and you may also have other rights that vary from country to country, or state to state. Additional Products",
    },
    section6: {
      h2: "Additional Products",
      p1: "Specialty Vapor Filters are available upon request/approval.",
    },
  },
}
