import React from "react";
import Header from "./header";
import Footer from "./footer";
import Main from "./main";
import navItems from '../data/navItems';
export default function Layout(props) {
    const breadcrumb = [];

    const findPageProps = navItems => {
        const foundItem = navItems.find(navItem => typeof window !== 'undefined' && window.location.pathname.includes(navItem.to));

        foundItem && breadcrumb.push(foundItem);
        if (foundItem && foundItem.children) {
            return findPageProps(foundItem.children) || foundItem;
        }

        return foundItem;
    };

    const currentPageProps = findPageProps(navItems);
    return <div>
        <Header navItems={navItems}></Header>
        {
            currentPageProps ?
                <Main
                    currentPageProps={currentPageProps}
                    breadcrumb={breadcrumb}
                    children={props.children}
                ></Main> :
                <main>{props.children}</main>
        }
        <Footer navItems={navItems}></Footer>
    </div >;


}