import React, { useState, useEffect } from "react";
import { ReactComponent as MenuLogo } from "./icons/bars-solid.svg";
import BurgerMenu from "./icons/bars-solid.svg";
import { ReactComponent as CafStatementLogo } from './icons/Tag-DropDown-Logo.svg'
import navbarStyles from './navbar.module.scss';
import togglerStyles from './toggler.module.scss';
import './header.scss';
import '../../styles/main.scss';
import { Link as GatsbyLink } from 'gatsby';
import NavItems from './navItems';
import brandStyles from './brand.module.scss';
import CafStatement from './cafStatement';
// import phoneStyle from './phoneButton.module.scss';
import PhoneButtonMobile from './phoneButtonMobile';

export default function Header(props) {
    const navItems = props.navItems;

    const handleScroll = () => {
        setTop(window.scrollY === 0);
    };
    // console.log(typeof asdf)
    const [top, setTop] = useState(true);
    const [show, setShow] = useState(true);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [top]);


    const [navClosed, setNavClosed] = useState(true);
    const barStyle = { width: "2.25rem", height: "9px", background: "red", borderRadius: 2 }
    return (
      <header>
        <nav
          className={top ? navbarStyles.wrapper : navbarStyles.wrapperScrolled}
        >
          <button
            className={togglerStyles.toggler}
            onClick={() => setNavClosed(!navClosed)}
            // style={navClosed ? { color: "red" } : { color: "white" }}
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "space-evenly",
            // }}
          >
            {/* <MenuLogo></MenuLogo> */}
            {/* <img src={BurgerMenu}></img> */}
            <div
              className={togglerStyles.bar}
              style={navClosed ? null : { backgroundColor: "white" }}
            ></div>
            <div
              className={togglerStyles.bar}
              style={navClosed ? null : { backgroundColor: "white" }}
            ></div>
            <div
              className={togglerStyles.bar}
              style={navClosed ? null : { backgroundColor: "white" }}
            ></div>
          </button>
          <div
            onMouseEnter={() => {
              setShow(true)
            }}
            onMouseLeave={() => {
              setShow(false)
            }}
            className={brandStyles.wrapper}
          >
            <GatsbyLink
              to="/"
              className={top ? brandStyles.linkTop : brandStyles.link}
            >
              <svg
                className={top ? brandStyles.logoTop : brandStyles.logo}
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 450 195"
                enableBackground="new 0 0 450 195"
                space="preserve"
              >
                <g>
                  <path
                    className={brandStyles.letter}
                    fill="#790000"
                    d="M391.486,64.941l13.255-28.425l-119.196,0.008c-6.288,0.036-11.37,5.13-11.37,11.419v117.661h34.688V71.213
		h72.483C385.776,71.188,389.611,68.654,391.486,64.941"
                  ></path>
                  <path
                    className={brandStyles.letter}
                    fill="#790000"
                    d="M222.648,36.452h-28.871c-4.422,0.025-8.247,2.561-10.131,6.252l-57.322,122.939h38.284l40.001-85.787
		l40.011,85.787h38.273L222.648,36.452z"
                  ></path>
                  <path
                    fill="#FFFFFF"
                    d="M173.549,161.214c0,0,25.301-47.37,57.065-63.483c60.295-30.591,108.814-1.977,159.543-6.784
		c28.432-2.694,47.692-10.768,47.692-10.768s-18.368,28.64-39.505,37.423c-40.812,16.95-91.743-5.105-137.299-5.924
		C216.344,110.876,173.549,161.214,173.549,161.214"
                  ></path>
                  <path
                    fill="#EC1C24"
                    d="M170.772,165.702c0,0,25.301-47.374,57.065-63.484c60.295-30.59,115.93,1.975,166.253-6.04
		c28.57-4.543,51.321-20.667,50.569-20.285c0,0-27.953,37.413-49.093,46.196c-40.809,16.95-91.743-5.105-137.298-5.924
		C213.559,115.363,170.772,165.702,170.772,165.702"
                  ></path>
                  <path
                    className={brandStyles.letter}
                    fill="#790000"
                    d="M101.908,118.94l-0.025,0.019c-6.033,9.271-16.451,15.069-27.64,15.069
		c-18.186,0-32.986-14.79-32.986-32.977s14.8-32.987,32.986-32.987c11.189,0,21.616,5.802,27.64,15.082c0,0,0.025,0.009,0.025,0.015
		c2.032,3.092,5.519,5.133,9.487,5.149h29.384l-0.663-2.834c-7.239-30.683-34.316-52.115-65.874-52.115
		c-37.324,0-67.692,30.368-67.692,67.69c0,37.324,30.368,67.684,67.692,67.684c31.557,0,58.634-21.429,65.874-52.116l0.663-2.834
		h-29.335C107.452,113.793,103.948,115.843,101.908,118.94"
                  ></path>
                </g>
              </svg>
            </GatsbyLink>
            <div>
              <CafStatement show={show}></CafStatement>
            </div>
          </div>

          {NavItems({ navItems, navClosed, top })}
          <PhoneButtonMobile></PhoneButtonMobile>
        </nav>
      </header>
    )
}