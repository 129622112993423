import React from "react";
import { ReactComponent as FacebookIcon } from './icons/facebook-square-brands.svg';
import { ReactComponent as InstagramIcon } from './icons/instagram-square-brands.svg';
import { ReactComponent as LinkedInIcon } from './icons/linkedin-brands.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter-square-brands.svg';
import { ReactComponent as YouTubeIcon } from './icons/youtube-square-brands.svg';
// import SocialBarStyles from './socialBar.module.scss';

export default function (props) {
    // console.log(props.className);
    return <div className={props.className}>
        {/* <span> */}
            <a
                className={props.classNameFacebook}
                href="https://www.facebook.com/CleanAirFilter/"
                target="_blank"
                rel="noreferrer">
                <FacebookIcon ></FacebookIcon>
            </a>
            <a
                className={props.classNameInstagram}
                href="https://www.instagram.com/cleanairfilter/"
                target="_blank"
                rel="noreferrer"
            >
                <InstagramIcon ></InstagramIcon>
            </a>
            <a
                className={props.classNameLinkedIn}
                href="http://www.facebook.com"
                target="_blank"
                rel="noreferrer">
                <LinkedInIcon ></LinkedInIcon>
            </a>

        {/* </span> */}
        {/* <span> */}
            <a
                className={props.classNameTwitter}
                href="https://twitter.com/cleanair_filter/"
                target="_blank"
                rel="noreferrer">
                <TwitterIcon ></TwitterIcon>
            </a>
            <a
                className={props.classNameYouTube}
                href="http://www.facebook.com"
                target="_blank"
                rel="noreferrer">
                <YouTubeIcon ></YouTubeIcon>
            </a>
        {/* </span> */}
    </div>;
}